const packageRoutes = [
  {
    path: '/packages/:clubName/:clubId',
    name: 'packages',
    component: () => import('./clubPackages/clubPackages.vue'),
    meta: {
      requiresAuth: true,
      squeezePage: true,
    },
  },
  {
    path: '/packages/:clubId',
    name: 'packages',
    component: () => import('./clubPackages/clubPackages.vue'),
    meta: {
      requiresAuth: true,
      squeezePage: true,
    },
  },
  {
    path: '/packages/:clubId',
    name: 'packages',
    component: () => import('./clubPackages/clubPackages2.vue'),
    meta: {
      requiresAuth: false,
      squeezePage: true,
    },
  },
];

export default packageRoutes;
