<template>
  <div class="h-screen min-h-screen w-full min-w-full">
    <div
      class="h-full min-h-full object-cover"
      :style="getBackground()"
    >
      <div
        class="flex h-full items-center justify-center text-center"
      >
        <transition name="fade">
          <div
            class="mx-auto max-w-3xl bg-white p-4 shadow-md"
          >
            <div class="brand-heading h-full border p-2 text-center text-base xxxs:text-lg xxs:p-4 xxs:text-xl xs:p-8 xs:text-2xl md:text-3xl lg:text-4xl">
              <div>
                <div class="text-brand-500">
                  We're here to help
                </div>
                <div class="mt-2">
                  <div class="mt-2 text-gray-700">
                    <span class="text-brand-500">T: </span>{{ club.telephone }}
                  </div>
                  <div class="mt-2 text-gray-700">
                    <span class="text-brand-500">E: </span>{{ club.email }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { nodeEnv } from '@/environment/environment';
import services from '@/app/shared/services';

export default {
  name: 'App',

  data() {
    return {
      clubId: 0,
      clubDetails: null,

      openFAQ1: false,
      openFAQ2: false,
      openFAQ3: false,
      openFAQ4: false,
      openFAQ5: false,
      openFAQ6: false,
      openFAQ7: false,
      openFAQ8: false,
      openFAQ9: false,

      baseUrl: process.env.VUE_APP_BASE_URL,
      environment: undefined,
    };
  },

  computed: {
    ...mapState({
      homeClubId: state => state.user.homeClubId,
      flexiPoints: state => state.user.flexiPoints,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
      hasSpa: 'hasSpa',
    }),
  },

  created() {
    this.clubId = this.homeClubId;

    if (this.clubId === 0) {
      this.clubId = this.$route.params.clubId;
    }

    this.$store.commit('setOverridingClubId', this.clubId);
  },

  mounted() {
    this.environment = nodeEnv;

    services.club.clubDetails(this.clubId).then((data) => {
      this.clubDetails = data;
    });
  },

  methods: {
    openUrl(url) {
      window.open(url);
    },

    getBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${process.env.VUE_APP_BASE_URL}img/help.jpg'); background-size: cover; background-position: center;`;
    },
  },

};
</script>
