<template>
  <div>
    <router-link :to="getRoute()">
      <div
        class="cursor-pointer border-b p-2 pl-0 pr-3 text-sm hover:bg-gray-200"
        :class="notification.read?'bg-white':'bg-gray-100'"
        @click="closeNotificationMenu()"
      >
        <div class="flex items-center">
          <div class="w-10 text-center text-base">
            <svg
              v-if="notification.type === 0"
              class="mx-auto h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="calendar-day"
              role="img"
              viewBox="0 0 448 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm192 176a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16zm112-240h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
            /></g></svg>
            <svg
              v-if="notification.type === 2"
              class="mx-auto h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="user-friends"
              role="img"
              viewBox="0 0 640 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z"
            /></g></svg>
            <svg
              v-if="notification.type === 3"
              class="mx-auto h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="user"
              role="img"
              viewBox="0 0 448 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M352 128A128 128 0 1 1 224 0a128 128 0 0 1 128 128z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M313.6 288h-16.7a174.1 174.1 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"
            /></g></svg>
            <svg
              v-if="notification.type === 4"
              class="mx-auto h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="ticket-alt"
              role="img"
              viewBox="0 0 576 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M576 208v-96a48 48 0 0 0-48-48H48a48 48 0 0 0-48 48v96a48 48 0 0 1 0 96v96a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48v-96a48 48 0 0 1 0-96zm-96 176H96V128h384z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M480 128v256H96V128z"
            /></g></svg>
            <svg
              v-if="notification.type === 5"
              class="mx-auto h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="money-check-edit"
              role="img"
              viewBox="0 0 640 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M640 192v288a32 32 0 0 1-32 32H32a32 32 0 0 1-32-32V192a32 32 0 0 1 32-32h171.06L331 288H104a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h259l64 64H104a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h456a16 16 0 0 0 16-16v-74.46a64 64 0 0 0-18.74-45.26L437 160h171a32 32 0 0 1 32 32z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M534.64 302.91L303.18 71.47l-71.7 71.7 231.39 231.45a32 32 0 0 0 22.64 9.38H528a16 16 0 0 0 16-16v-42.46a32 32 0 0 0-9.36-22.63zM238.78 7a24.1 24.1 0 0 0-33.9 0L167 44.87a24 24 0 0 0 0 33.8l41.9 41.9 71.7-71.8z"
            /></g></svg>
            <svg
              v-if="notification.type > 5"
              class="mx-auto h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="info-circle"
              role="img"
              viewBox="0 0 512 512"
            ><path
              fill="currentColor"
              d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
            /></svg>
          </div>
          <div>
            <div class="leading-tight">
              {{ notification.object.Description }}
            </div>
            <div class="text-xs leading-tight text-gray-600">
              {{ timeSince() }}
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {

  components: {

  },

  props: {
    notification: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {

    };
  },

  methods: {
    getIcon() {
      switch (this.notification.type) {
        case 0:
          return 'fad fa-calendar-day';
        case 1:
          return 'fad fa-trophy';
        case 2:
          return 'fad fa-user-friends';
        case 3:
          return 'fad fa-user';
        case 4:
          return 'fad fa-ticket-alt';
        case 5:
          return 'fad fa-shekel-sign';
        default:
          return 'fad fa-info';
      }
    },

    getRoute() {
      switch (this.notification.type) {
        case 0:
          return '/';
        case 1:
          return '/Trophies';
        case 2:
          return '/Buddies';
        case 3:
          return '/Account';
        case 4:
          return '/Account';
        case 5:
          return '';
        default:
          return '';
      }
    },

    timeSince() {
      const date = new Date(this.notification.published);

      const seconds = Math.floor((new Date() - date) / 1000);

      let interval = Math.floor(seconds / 31536000);

      if (interval === 1) {
        return `${interval} year ago`;
      } if (interval > 1) {
        return `${interval} years ago`;
      }

      interval = Math.floor(seconds / 2592000);
      if (interval === 1) {
        return `${interval} month ago`;
      } if (interval > 1) {
        return `${interval} months ago`;
      }

      interval = Math.floor(seconds / 86400);
      if (interval === 1) {
        return `${interval} day ago`;
      } if (interval > 1) {
        return `${interval} days ago`;
      }

      interval = Math.floor(seconds / 3600);
      if (interval === 1) {
        return `${interval} hour ago`;
      } if (interval > 1) {
        return `${interval} hours ago`;
      }

      interval = Math.floor(seconds / 60);
      if (interval === 1) {
        return `${interval} minute ago`;
      } if (interval > 1) {
        return `${interval} minutes ago`;
      }

      return `${Math.floor(seconds)} seconds ago`;
    },

    closeNotificationMenu() {
      this.$emit('click', false);
    },
  },

};


</script>
