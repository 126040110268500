<template>
  <div
    v-if="hasMessage"
    class="rounded-lg rounded-l-none border-l-4 border-brand-500 bg-blue-50 p-4 text-left"
  >
    <div class="flex">
      <div class="mt-1 shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="fad"
          class="h-5 w-5 fill-current text-brand-500"
          role="img"
          viewBox="0 0 512 512"
        >
          <g class="fa-group">
            <path
              class="fa-secondary"
              fill="currentColor"
              d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 110a42 42 0 1 1-42 42 42 42 0 0 1 42-42zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12z"
              opacity="0.4"
            />
            <path
              class="fa-primary"
              fill="currentColor"
              d="M256 202a42 42 0 1 0-42-42 42 42 0 0 0 42 42zm44 134h-12V236a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h12v64h-12a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12z"
            />
          </g>
        </svg>
      </div>
      <div class="ml-4 list-disc">
        <div>
          <div
            class="brand-heading text-sm leading-normal text-brand-500"
          >
            {{ title }}
          </div>
        </div>
        <bbob-bbcode
          container="div"
          class="brand-body text-sm text-brand-500"
          :plugins="plugins"
        >
          {{ message }}
        </bbob-bbcode>
      </div>
    </div>
  </div>
</template>

<script>
import ourBbcodePreset from '@/config/bbcodePreset';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      plugins: [ourBbcodePreset()],
    };
  },

  computed: {
    hasMessage() {
      return (
        (this.message !== '' && this.message !== null) || (this.title !== '' && this.title !== null)
      );
    },
    hasLinkMessage() {
      return this.linkMessage !== '' && this.linkMessage !== null;
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
