import booking from './booking.vue';

const bookingRoutes = [
  {
    path: '/booking',
    component: booking,
    children: [
      {
        path: '',
        name: 'bookingType',
        component: () => import('./bookingType.vue'),
        meta: {
          requiresAuth: true,
          title: 'Booking',
        },
      },
      {
        path: 'clubs',
        name: 'clubs',
        component: () => import('./clubSelection/clubSelection.vue'),
        meta: {
          requiresAuth: true,
          title: 'Clubs',
        },
      },
      {
        path: 'clubs/:clubId',
        name: 'clubHouse',
        component: () => import('./clubHouse/clubHouse.vue'),
        meta: {
          requiresAuth: true,
          hideHomeClub: true,
        },
        props: true,
      },
      {
        path: 'clubs/:clubId/:course',
        name: 'TeeTimes',
        component: () => import('./clubHouse/clubTeeTimes.vue'),
        meta: {
          requiresAuth: true,
          hideHomeClub: true,
        },
        props: true,
      },
      {
        path: '',
        redirect: { name: 'clubs' },
      },
    ],
  },
];

export default bookingRoutes;
