<template>
  <div
    class="buddyCardBg z-0 mx-auto mb-2 max-w-5xl border-b pb-2 xs:mb-4 xs:pb-0 xs:shadow-lg"
  >
    <div class="z-0 flex w-full min-w-full">
      <div
        class="hidden h-32 w-full select-none items-center justify-center bg-brand-500 object-cover align-top xxs:w-auto xs:flex"
      >
        <div
          class="defaultFont mx-12 mb-1 w-10 text-center text-5xl font-thin text-white xs:mx-24"
        >
          {{ getInitials() }}
        </div>
      </div>

      <div class="relative flex flex-grow justify-between xs:inline-block">
        <div class="flex items-center p-3 px-4">
          <div class="defaultFont mr-4 block bg-brand-500 p-3 px-4 text-lg tracking-wider text-white xs:hidden">
            <div class="w-6 text-center">
              {{ getInitials() }}
            </div>
          </div>

          <div class="">
            <div class="flex items-center justify-between">
              <div
                class="brand-heading inline-block text-xl tracking-wider text-brand-500 sm:text-2xl"
              >
                {{ buddy.name }}
              </div>
            </div>

            <div
              class="brand-body text-sm tracking-wider text-gray-700 xs:text-base"
            >
              {{ buddy.club }}
            </div>
          </div>
        </div>

        <div
          v-if="type === 0"
          class="absolute bottom-0 right-0 hidden w-full min-w-full border-t bg-gray-100 p-2 px-4 xs:block"
        >
          <div
            class="flex items-center"
          >
            <div
              class="inline-block cursor-pointer text-red-700 hover:text-red-600"
              @click="openRemoveBuddy = true"
            >
              Remove
            </div>
          </div>
        </div>

        <div
          v-if="type === 1"
          class="absolute bottom-0 right-0 hidden w-full min-w-full border-t bg-gray-100 p-2 px-4 xs:block"
        >
          <div class="inline-block">
            <div
              class="flex cursor-pointer items-center"
              @click="openAcceptBuddy = true"
            >
              <div class="inline-block text-green-700 hover:text-green-600">
                Accept
              </div>
            </div>
          </div>

          <div class="ml-4 inline-block">
            <div
              class="flex items-center"
            >
              <div
                class="inline-block cursor-pointer text-red-700 hover:text-red-600"
                @click="openDeclineBuddy = true"
              >
                Decline
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="type === 2"
          class="absolute bottom-0 right-0 hidden w-full min-w-full border-t bg-gray-100 p-2 px-4 xs:block"
        >
          <div
            class="flex items-center"
          >
            <div
              class="inline-block cursor-pointer text-red-700 hover:text-red-600"
              @click="openCancelBuddy = true"
            >
              Cancel Request
            </div>
          </div>
        </div>

        <div
          v-if="type === 0"
          class="flex cursor-pointer items-center py-3 pr-4 xs:hidden"
        >
          <div
            class="rounded-full border bg-white p-2 text-red-700 shadow-sm"
            @click="openRemoveBuddy = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="user-minus"
              class="h-5 w-5"
              role="img"
              viewBox="0 0 640 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M640 224v32a16 16 0 0 1-16 16H432a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h192a16 16 0 0 1 16 16z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M313.6 288h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288zM224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128z"
            /></g></svg>
          </div>
        </div>

        <div
          v-if="type === 1"
          class="flex cursor-pointer items-center py-3 pr-4 xs:hidden"
        >
          <div
            class="mr-2 rounded-full border bg-white p-2 text-red-700 shadow-sm"
            @click="openDeclineBuddy = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="times"
              class="h-5 w-5"
              role="img"
              viewBox="0 0 320 512"
            ><path
              fill="currentColor"
              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
            /></svg>
          </div>
          <div
            class="rounded-full border bg-white p-2 text-green-700 shadow-sm"
            @click="openAcceptBuddy = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="check"
              class="h-5 w-5"
              role="img"
              viewBox="0 0 512 512"
            ><path
              fill="currentColor"
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
            /></svg>
          </div>
        </div>

        <div
          v-if="type === 2"
          class="flex cursor-pointer items-center py-3 pr-4 xs:hidden"
        >
          <div
            class="rounded-full border bg-white p-2 text-red-700 shadow-sm"
            @click="openCancelBuddy = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="times"
              class="h-5 w-5"
              role="img"
              viewBox="0 0 320 512"
            ><path
              fill="currentColor"
              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
            /></svg>
          </div>
        </div>
      </div>
    </div>

    <CancelBuddy
      v-if="openCancelBuddy"
      :buddy="buddy"
    />
    <RemoveBuddy
      v-if="openRemoveBuddy"
      :buddy="buddy"
    />
    <AcceptBuddy
      v-if="openAcceptBuddy"
      :buddy="buddy"
      @getBuddyRequests="getBuddyRequests"
      @getBuddies="getBuddies"
    />
    <DeclineBuddy
      v-if="openDeclineBuddy"
      :buddy="buddy"
      @getBuddyRequests="getBuddyRequests"
      @getBuddies="getBuddies"
    />
  </div>
</template>

<script>
import CancelBuddy from './cancelBuddy.vue';
import RemoveBuddy from './removeBuddy.vue';
import AcceptBuddy from './acceptBuddy.vue';
import DeclineBuddy from './declineBuddy.vue';

export default {

  components: {
    CancelBuddy,
    RemoveBuddy,
    AcceptBuddy,
    DeclineBuddy,
  },
  props: {
    buddy: {
      type: Object,
      default: null,
    },
    type: {
      // 0 - Buddy, 1 - Request, 2 - Pending
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      openCancelBuddy: false,
      openRemoveBuddy: false,
      openAcceptBuddy: false,
      openDeclineBuddy: false,
    };
  },

  methods: {
    getInitials() {
      const split = this.buddy.name.split(' ');
      return split[0].charAt(0) + split[(split.length - 1)].charAt(0);
    },

    getBuddyRequests() {
      this.$emit('getBuddyRequests');
    },

    getBuddies() {
      this.$emit('getBuddies');
    },
  },

};

</script>

<style>
  @media (min-width: 640px){
    .buddyCardBg {
      background-color: #fff;
    }
  }

  @media (max-width: 640px){
    .buddyCardBg {
      background-color: rgb(243, 243, 243)
    }
  }

</style>
