<template>
  <div class="mx-2 border-gray-200 md:border-x">
    <div class="w-40 border-t-2 border-brand-500 bg-white shadow-md">
      <div class="p-2 text-left leading-none">
        <div class="border-b pb-1">
          <div
            v-if="clubPackage.NewAwayPoints !== 0"
            class="inline text-3xl leading-none text-brand-500"
          >
            {{ clubPackage.NewAwayPoints }}
          </div>
          <div
            v-if="clubPackage.NewHomePoints !== 0"
            class="inline text-3xl leading-none text-brand-500"
          >
            {{ clubPackage.NewHomePoints }}
          </div>
          <div
            class="-ml-1 inline text-lg leading-none"
          >
            <span v-if="disablePoints">rounds</span><span v-else>{{ type }}</span>
          </div>
        </div>
        <div
          class="pt-2 text-xl leading-none"
        >
          {{ clubPackage.Currency.CurrencySymbol }}{{ clubPackage.NewPrice }}
        </div>
      </div>


      <div>
        <div
          v-if="!isInBasket"
          class="brand-button transition-200 transition-ease-in-out max-w-full cursor-pointer py-1
          text-center text-sm shadow-lg transition-all"
          @click="addToBasket"
        >
          <div v-if="!loading">
            Add to basket
          </div>
          <LoadingSpinner
            v-if="loading"
            :white="true"
          />
        </div>
        <div
          v-else
          class="brand-button transition-200 transition-ease-in-out max-w-full cursor-pointer py-1
          text-center text-sm shadow-lg transition-all"
          @click="emptyBasket"
        >
          <div v-if="!loading">
            Remove
          </div>
          <LoadingSpinner
            v-if="loading"
            :white="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SetBasketModel } from 'pmg-services';
import LoadingSpinner from '../../base/loadingSpinner.vue';
import { nodeEnv } from '@/environment/environment';

export default {

  components: {
    LoadingSpinner,
  },

  props: {
    clubPackage: {
      type: Object,
      default: null,
    },
    promocode: {
      type: String,
      default: '',
    },
    homeClubId: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'points',
    },
  },

  data() {
    return {
      loading: false,
      environment: undefined,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
    }),

    isInBasket() {
      if (this.basket !== undefined && this.basket !== null && this.basket.BundleId === this.clubPackage.BundleId) {
        return true;
      }
      return false;
    },

    ...mapGetters({
      disablePoints: 'disablePoints',
    }),
  },

  mounted() {
    this.environment = nodeEnv;
  },

  methods: {
    addToBasket() {
      this.loading = true;

      const bundle = new SetBasketModel(this.clubPackage.BundleId, this.promocode, this.homeClubId, null);
      this.$store.dispatch('setBasketBundle', bundle).then(() => {
        this.loading = false;
        this.$store.dispatch('setBasketMenuState', true);
      });
    },

    emptyBasket() {
      this.loading = true;
      this.$store.dispatch('emptyBasket').then(() => {
        this.loading = false;
      });
    },

  },

};
</script>
