import { MemberSummaryModel, TimeUntilExpiryModel } from 'pmg-services';
import services from '@/app/shared/services';
import { environment } from '@/environment/environment';
// eslint-disable-next-line import/no-cycle
import { asyncRouterPush } from '@/app/shared/services/routerService/routerService';
import router from '@/app/app-routes';

const userStore = {
  state: {
    homePoints: 0,
    flexiPoints: 0,
    refreshingPoints: true, // true because it happens on mount
    userGuid: '',
    username: '',
    forename: '',
    surname: '',
    contactNumber: '',
    homeClubId: 0,
    homeClubName: '',
    membershipType: null,
    completedFirstPayment: false,
    membershipExpires: null,
    isCorporateMember: false,
    companyName: '',
    bannerUrl: '',
    thumbUrl: '',
    autoRenewOn: false,
    isAdmin: false,
    isInArrears: false,
    IsExpired: false,
    IsImpersonating: false,
    refreshingMemberSummary: true, // true because it happens on mount
    pointlessIncrement: 0,
    notPersistedIncrement: 0,
    isAuthenticated: false,
    flexiPointsAllowed: true,
    packageName: null,
  },

  getters: {
    getMemberSummary: state => new MemberSummaryModel(
      0,
      state.userGuid,
      state.username,
      state.forename,
      state.surname,
      state.contactNumber,
      state.homeClubId,
      state.homeClubName,
      state.homePoints,
      state.flexiPoints,
      state.membershipType,
      state.completedFirstPayment,
      state.membershipExpires,
      state.isCorporateMember,
      state.companyName,
      state.bannerUrl,
      true,
      state.thumbUrl,
      state.autoRenewOn,
      state.isAdmin,
      state.isInArrears,
      state.IsExpired,
      state.IsImpersonating,
      state.flexiPointsAllowed,
      state.packageName,
    ),

    getMemberExpiryInfo: state => new TimeUntilExpiryModel(
      state.membershipExpires,
    ),
  },

  mutations: {


    setRefreshingPoints(state, value) {
      state.refreshingPoints = value;
    },
    setHomePoints(state, value) {
      state.homePoints = value;
    },
    setFlexiPoints(state, value) {
      state.flexiPoints = value;
    },
    pushMemberSummary(state, memberSummary) {
      state.userGuid = memberSummary.UserGuid;
      state.username = memberSummary.Username;
      state.forename = memberSummary.Forename;
      state.surname = memberSummary.Surname;
      state.contactNumber = memberSummary.ContactNumber;
      state.homeClubId = memberSummary.HomeClubId;
      state.homeClubName = memberSummary.HomeClubName;
      state.membershipType = memberSummary.MembershipType;
      state.completedFirstPayment = memberSummary.CompletedFirstPayment;
      state.membershipExpires = memberSummary.MembershipExpires;
      state.isCorporateMember = memberSummary.IsCorporateMember;
      state.companyName = memberSummary.CompanyName;
      state.bannerUrl = memberSummary.BannerUrl;
      state.thumbUrl = memberSummary.ThumbUrl;
      state.autoRenewOn = memberSummary.AutoRenewOn;
      state.isAdmin = memberSummary.IsAdmin;
      state.isInArrears = memberSummary.IsInArrears;
      state.IsExpired = memberSummary.IsExpired;
      state.IsImpersonating = memberSummary.IsImpersonating;
      state.flexiPointsAllowed = memberSummary.FlexiPointsAllowed;
      state.packageName = memberSummary.PackageName;
    },
    emptyMemberSummary(state) {
      state.userGuid = '';
      state.username = '';
      state.forename = '';
      state.surname = '';
      state.contactNumber = '';
      state.homeClubId = 0;
      state.homeClubName = '';
      state.membershipType = null;
      state.completedFirstPayment = false;
      state.membershipExpires = null;
      state.isCorporateMember = false;
      state.companyName = '';
      state.bannerUrl = '';
      state.thumbUrl = '';
      state.autoRenewOn = false;
      state.isAdmin = false;
      state.isInArrears = false;
      state.IsExpired = false;
      state.IsImpersonating = false;
      state.flexiPointsAllowed = false;
      state.packageName = undefined;
    },
    setRefreshingMemberSummary(state, value) {
      state.refreshingMemberSummary = value;
    },
    setIsAuth(state, value) {
      state.isAuthenticated = value;
    },
  },

  actions: {
    setAuth(context, value) {
      context.commit('setIsAuth', value);
    },

    async refreshPoints({ commit }, realtime = false) {
      commit('setRefreshingPoints', true);
      await services.member.getMemberPoints(realtime).then(
        (memberPoints) => {
          commit('setHomePoints', memberPoints.homePoints);
          commit('setFlexiPoints', memberPoints.flexiPoints);
          commit('setRefreshingPoints', false);
        },
        (error) => {
          console.log(error);
        },
      );
    },

    async emptyMemberSummary({ commit }) {
      commit('emptyMemberSummary');
    },

    populateMemberSummary({ commit, dispatch, state }) {
      return new Promise((resolve) => {
        services.account.cookieAuthenticated().then((data) => {
          commit('setRefreshingPoints', true);
          commit('setRefreshingMemberSummary', true);
          if (data) {
            services.member.memberSummary().then((summary) => {
              services.club.clubDetails(summary.HomeClubId).then((clubDetails) => {
                // eslint-disable-next-line no-param-reassign
                // clubDetails.Courses[1].FacilityType = 1;
                commit('setClubDetails', clubDetails);
                if (summary !== undefined) {
                  commit('setIsAuth', true);
                  localStorage.setItem('homeClubId', JSON.stringify(summary.HomeClubId));

                  if (summary.IsAdmin) {
                    if (process.env.NODE_ENV === 'production') {
                      const url = this.homeClubId === 523 ? 'https://dashboard.xviii.golf/' : `${environment}/#!/`;
                      window.location.replace(url);
                    } else {
                      const url = summary.HomeClubId === 523 ? 'https://localhost:3000/' : `${environment}/#!/`;
                      window.location.replace(url);
                    }
                  }

                  if (summary.IsExpired && !summary.CompletedFirstPayment && router.currentRoute.meta.requiresAuth) {
                    asyncRouterPush('/Checkout').then(() => {
                      commit('pushMemberSummary', summary);
                      resolve();
                    }).catch(() => {
                      commit('pushMemberSummary', summary);
                      resolve();
                    });
                  } else {
                    commit('pushMemberSummary', summary);
                    resolve();
                  }

                  commit('setHomePoints', summary.HomePoints);
                  commit('setFlexiPoints', summary.AwayPoints);
                  dispatch('populateAdditionalBookers', summary.HomeClubId, { root: true });
                } else if (state.isAuthenticated) {
                  commit('setIsAuth', false);
                  location.reload();
                }

                commit('setRefreshingPoints', false);
                commit('setRefreshingMemberSummary', false);
              });
            });
          } else {
            commit('setIsAuth', false);
            // location.reload();
          }
        });
      });
    },
  },

};

export { userStore };
