<template>
  <div class="fixed inset-x-0 bottom-0 z-30 px-4 pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>


    <div
      v-if="loading"
      class="fixed left-0 top-0 z-20 flex h-screen min-h-screen w-screen min-w-full items-center justify-center"
    >
      <loadingSpinner class="text-centers mx-auto" />
    </div>

    <div
      v-if="!loading"
      class="z-10 transform overflow-hidden bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="mb-4">
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="users"
            class="h-6 w-6 text-green-600"
            role="img"
            viewBox="0 0 640 512"
          ><g class="fa-group"><path
            class="fa-secondary"
            fill="currentColor"
            d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
            opacity="0.4"
          /><path
            class="fa-primary"
            fill="currentColor"
            d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
          /></g></svg>
        </div>

        <div class="mt-2 text-center">
          <h3 class="brand-heading text-lg font-medium leading-6 text-gray-800">
            Accept {{ currentTheme.buddiesText }}
          </h3>
          <div class="mt-4">
            <p class="text-sm leading-5 text-gray-700">
              Are you sure you want to accept this {{ currentTheme.buddiesText }} invitation from {{ buddy.name }}?
            </p>
          </div>

          <div class="mt-4 text-left">
            <div class="text-sm leading-5 text-gray-700">
              <div class="text-sm text-gray-700">
                This will mean {{ buddy.name }} will be able to:
              </div>
              <div class="text-sm text-gray-700">
                <div class="inline-block text-xl">
                  &bull;
                </div> Add you as a player to a booking, using your points,
                without confirmation
              </div>
              <div class="text-sm text-gray-700">
                <div class="inline-block text-xl">
                  &bull;
                </div> Know if you have insufficient points to make a booking,
                and have the option to use their points instead
              </div>
              <div class="text-sm text-gray-700">
                <div class="inline-block text-xl">
                  &bull;
                </div> and you will be able to do the same
              </div>

              <div class="mt-2 text-sm text-gray-700">
                Additionally, any player added to a booking will have the option to
                cancel the booking (subject to normal cancellation terms) which will cancel and
                refund the entire booking.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-2 text-sm leading-5 text-red-700">
        You shouldn't accept {{ currentTheme.buddiesText }} invitations from people you don't know. You can revoke {{ currentTheme.buddiesText }} priviledges at any time.
      </div>

      <div
        class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
      >
        <span class="flex w-full shadow-sm sm:col-start-2">
          <PrimaryButton
            :text="'Accept ' + currentTheme.buddiesTextPlural"
            :text-size="'text-base'"
            :full-width="true"
            @click="acceptBuddyRequest"
          />
        </span>
        <span
          class="mt-3 flex w-full sm:col-start-1 sm:mt-0"
        >
          <SecondaryButton
            :text="'Close'"
            :text-size="'text-base'"
            :full-width="true"
            :loading="loading"
            @click="$parent.openAcceptBuddy = false"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/app/shared/services';
import LoadingSpinner from '../base/loadingSpinner.vue';
import PrimaryButton from '../base/button/primaryButton.vue';
import SecondaryButton from '../base/button/secondaryButton.vue';
import { mapGetters } from 'vuex';

export default {

  components: {
    LoadingSpinner,
    PrimaryButton,
    SecondaryButton,
  },

  props: {
    buddy: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  methods: {
    acceptBuddyRequest() {
      this.loading = true;
      services.buddy.acceptBuddyRequest(this.buddy.target).then((result) => {
        if (result.status === 204) {
          this.$emit('getBuddyRequests');
          this.$emit('getBuddies');
          this.$emit('closeAcceptBuddy');
        } else {
          // Failed
          this.loading = false;
        }
      });
    },

  },

};


</script>
