const accountRoutes = [
  {
    path: '/account',
    redirect: 'account/profile',
    meta: {
      title: 'My Account',
    },
  },
  {
    path: '/account/profile',
    name: 'profile',
    component: () => import('./profile/profile.vue'),
    meta: {
      title: 'My Account',
    },
  },
  {
    path: '/account/profile/:view',
    name: 'profileView',
    component: () => import('./profile/profile.vue'),
    meta: {
      title: 'My Account',
    },
  },
  {
    path: '/2FA/:TFAToken/:UseFallbackUI',
    name: '2FA',
    component: () => import('./login/2FA.vue'),
    meta: {
      title: '2FA',
      noLayout: true,
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./login/login.vue'),
    meta: {
      title: 'Login',
      noLayout: true,
      requiresAuth: false,
    },
  },
  {
    path: '/login/:clubName/:clubId',
    name: 'loginForClub',
    component: () => import('./login/login.vue'),
    meta: {
      title: 'Login',
      noLayout: true,
      overrideStyle: true,
      requiresAuth: false,
    },
  },
  {
    path: '/register/:id',
    name: 'register',
    component: () => import('./register/register.vue'),
    meta: {
      title: 'Register',
      noLayout: true,
      requiresAuth: false,
    },
  },
  {
    path: '/Arrears',
    name: 'arrears',
    component: () => import('./arrears/arrears.vue'),
    meta: {
      title: 'Account Locked',
    },
  },
  {
    path: '/magiclink',
    name: 'magiclink',
    component: () => import('./magiclink.vue'),
    meta: {
      title: 'Magic Link',
    },
  },
  {
    path: '/login/*',
    name: 'loginBad',
    redirect: 'login',
  },
];

export default accountRoutes;
