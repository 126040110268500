const errorRoutes = [
  {
    path: '/error/offline',
    name: 'offline',
    component: () => import('./offline/offline.vue'),
    meta: {
      title: 'Offline',
      noLayout: true,
      requiresAuth: false,
    },
  },
];

export default errorRoutes;
