<template>
  <div>
    <div class="container select-none">
      <ul
        id="gn-menu"
        class="gn-menu-main menu-line fixed left-0 top-0 z-20 m-0 w-full list-none bg-white p-0 text-xs font-light normal-case text-gray-700"
      >
        <li
          v-show="!enableSqueeze"
          class="gn-trigger block"
        >
          <a
            id="menuButton"
            class="gn-icon gn-icon-menu block h-full w-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
          >
            <div
              class="flex h-full w-full items-center justify-center"
            >
              <svg
                class="h-6 w-6"
                style="margin-left: -5px;"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="bars"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
              /></svg>
            </div>
          </a>
          <nav class="gn-menu-wrapper fixed bottom-0 left-0 overflow-hidden bg-white">
            <div class="gn-scroller absolute h-full overflow-y-scroll">
              <ul
                class="gn-menu menu-line m-0 select-none list-none bg-white p-0 text-left text-lg font-light normal-case text-gray-700"
              >
                <li class="relative block select-none py-4 text-left sm:hidden">
                  <div class="flex items-center leading-tight">
                    <div
                      v-if="forename !== undefined"
                      class="brand-body ml-6 mr-8 text-xl tracking-wider text-brand-500"
                    >
                      {{ forename.charAt(0) }}{{ surname.charAt(0) }}
                    </div>
                    <div>
                      <div>{{ username }}</div>
                      <div class="font-brand text-sm uppercase">
                        <span v-if="!disablePoints">
                          {{ homePoints }} point<span v-if="homePoints !== 1">s</span> remaining
                        </span>
                        <span v-else>
                          {{ homePoints }} round<span v-if="homePoints !== 1">s</span> remaining
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="relative select-none">
                  <router-link
                    to="/"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-6 w-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="home"
                            role="img"
                            viewBox="0 0 576 512"
                          ><path
                            fill="currentColor"
                            d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                          /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Home</span>
                    </div>
                  </router-link>
                </li>

                <li
                  v-if="additionalBookers === null || additionalBookers.length === 0"
                  class="relative select-none"
                >
                  <router-link
                    :to="getBookingLink()"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-5 w-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-check"
                            role="img"
                            viewBox="0 0 448 512"
                          ><path
                            fill="currentColor"
                            d="M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z"
                          /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Book</span>
                    </div>
                  </router-link>
                </li>

                <li
                  v-if="hasSpa"
                  class="relative select-none"
                >
                  <div
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                    @click="spa()"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-7 w-7">
                          <svg
                            class="fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          ><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M183.1 235.3c33.7 20.7 62.9 48.1 85.8 80.5c7 9.9 13.4 20.3 19.1 31c5.7-10.8 12.1-21.1 19.1-31c22.9-32.4 52.1-59.8 85.8-80.5C437.6 207.8 490.1 192 546 192h9.9c11.1 0 20.1 9 20.1 20.1C576 360.1 456.1 480 308.1 480H288 267.9C119.9 480 0 360.1 0 212.1C0 201 9 192 20.1 192H30c55.9 0 108.4 15.8 153.1 43.3zM301.5 37.6c15.7 16.9 61.1 71.8 84.4 164.6c-38 21.6-71.4 50.8-97.9 85.6c-26.5-34.8-59.9-63.9-97.9-85.6c23.2-92.8 68.6-147.7 84.4-164.6C278 33.9 282.9 32 288 32s10 1.9 13.5 5.6z" /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Treatments</span>
                    </div>
                  </div>
                </li>

                <li
                  v-if="hasRestaurant || hasSpa"
                  class="relative select-none"
                >
                  <router-link
                    :to="getBookingLink()"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-7 w-7">
                          <svg
                            class="fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          ><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M162.7 2.5c-7.4-3.7-16.3-3.3-23.4 1.1S128 15.7 128 24V232c0 1.3 .1 2.7 .3 4c-.2 1.3-.3 2.6-.3 4v92.8C51.5 349.4 0 380.5 0 416c0 53 114.6 96 256 96s256-43 256-96s-114.6-96-256-96c-27.9 0-54.8 1.7-80 4.8V246.8l194.7-97.4c8.1-4.1 13.3-12.4 13.3-21.5s-5.1-17.4-13.3-21.5l-208-104zM288 416c0 17.7-28.7 32-64 32s-64-14.3-64-32s28.7-32 64-32s64 14.3 64 32z" /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Golf</span>
                    </div>
                  </router-link>
                </li>

                <li
                  v-if="hasRestaurant"
                  class="relative select-none"
                >
                  <div
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                    @click="dining()"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-6 w-6">
                          <svg
                            class="fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          ><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M384 48c0 20.9-13.4 38.7-32 45.3V112v64c0 70.7-57.3 128-128 128s-128-57.3-128-128V112 93.3C77.4 86.7 64 68.9 64 48C64 21.5 85.5 0 112 0c14.3 0 27.2 6.3 36 16.3C156.8 6.3 169.7 0 184 0c16.7 0 31.4 8.5 40 21.5C232.6 8.5 247.3 0 264 0c14.3 0 27.2 6.3 36 16.3C308.8 6.3 321.7 0 336 0c26.5 0 48 21.5 48 48zM304 176V160H144v16c0 44.2 35.8 80 80 80s80-35.8 80-80zM96 320l106.4 26.6-66.2 26.5C111.9 382.8 96 406.3 96 432.5V512H25.6C11.5 512 0 500.5 0 486.4c0-60.9 40.5-112.3 96-128.8V320zM352 512H128V432.5c0-13.1 8-24.9 20.1-29.7L352 321.2v36.3c55.5 16.5 96 68 96 128.8c0 14.1-11.5 25.6-25.6 25.6H352zM224 432c0-8.8-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16s16-7.2 16-16zm80 16c8.8 0 16-7.2 16-16s-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16z" /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Dining</span>
                    </div>
                  </div>
                </li>

                <li
                  v-if="additionalBookers !== null && additionalBookers.length > 1"
                  class="relative select-none"
                >
                  <div
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                    @click="additional()"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-6 w-6">
                          <svg
                            class="fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          ><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z" /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Additional Items</span>
                    </div>
                  </div>
                </li>

                <li
                  v-if="!hasSpa"
                  class="relative select-none"
                >
                  <router-link
                    :to="'/' + currentTheme.buddiesTextPlural"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-7 w-7">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="user-friends"
                            class="svg-inline--fa fa-user-friends fa-w-20"
                            role="img"
                            viewBox="0 0 640 512"
                          ><path
                            fill="currentColor"
                            d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
                          /></svg>
                        </div>
                      </div>
                      <span class="brand-heading -mb-1 text-xl">{{ capitalizeFirstLetter(currentTheme.buddiesTextPlural) }}</span>
                    </div>
                  </router-link>
                </li>

                <li class="relative select-none">
                  <router-link
                    to="/Account"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-7 w-7">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="user-cog"
                            class="svg-inline--fa fa-user-cog fa-w-20"
                            role="img"
                            viewBox="0 0 640 512"
                          ><path
                            fill="currentColor"
                            d="M610.5 373.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 400.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm201.2 226.5c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9 4.6c-6 3.4-12.8 5.3-19.6 5.3-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-5.5-17.7 1.9-36.4 17.9-45.7l7.9-4.6c-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-16-9.2-23.4-28-17.9-45.7.9-2.9 2.2-5.8 3.2-8.7-3.8-.3-7.5-1.2-11.4-1.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c10.1 0 19.5-3.2 27.2-8.5-1.2-3.8-2-7.7-2-11.8v-9.2z"
                          /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Account</span>
                    </div>
                  </router-link>
                </li>

                <li
                  v-if="homeClubId !== 537"
                  class="relative select-none"
                >
                  <router-link
                    to="/Help"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-6 w-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="question-circle"
                            class="svg-inline--fa fa-question-circle fa-w-16"
                            role="img"
                            viewBox="0 0 512 512"
                          ><path
                            fill="currentColor"
                            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
                          /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Help</span>
                    </div>
                  </router-link>
                </li>

                <li
                  v-else
                  class="relative select-none"
                >
                  <router-link
                    to="/Contact"
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-6 w-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="question-circle"
                            class="svg-inline--fa fa-question-circle fa-w-16"
                            role="img"
                            viewBox="0 0 512 512"
                          ><path
                            fill="currentColor"
                            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
                          /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Contact Us</span>
                    </div>
                  </router-link>
                </li>

                <li
                  class="relative select-none"
                  style="border-bottom: 1px solid #c6d0da;"
                  @click="logout"
                >
                  <a
                    class="brand-heading gn-icon block h-full cursor-pointer text-gray-800 no-underline hover:bg-brand-800 hover:text-white"
                  >
                    <div class="menu-line flex items-center">
                      <div class="ml-5 mr-7 inline-block h-8 w-8">
                        <div class="mx-auto mt-1 h-6 w-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="sign-out-alt"
                            class="svg-inline--fa fa-sign-out-alt fa-w-16"
                            role="img"
                            viewBox="0 0 512 512"
                          ><path
                            fill="currentColor"
                            d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                          /></svg>
                        </div>
                      </div>
                      <span class="-mb-1 text-xl">Logout</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </li>

        <li
          v-if="club !== undefined && club.clubId !== 0"
          id="homeClub"
          class="hidden md:block"
        >
          <router-link :to="getClubLink()">
            <div
              v-show="!hideHomeClub"
              class="brand-heading transition-ease inline-block h-full cursor-pointer select-none p-6 text-lg
              tracking-wider text-gray-800 no-underline transition-bg hover:bg-brand-800 hover:text-white md:text-xl"
            >
              <span class="">{{ club.clubName }}</span>
            </div>
          </router-link>
        </li>

        <span class="flex items-center justify-between pl-8 sm:float-right sm:pl-0">
          <li
            v-show="!summaryLoading && !enableSqueeze"
            class="hidden sm:block"
          >
            <div
              id="flexiPoints"
            >
              <span
                class="mx-5 text-base hover:text-gray-900"
              >
                <span v-if="false">
                  {{ homePoints }} Home &bull; {{ flexiPoints }} Flexi
                </span>
                <span
                  v-if="IsExpired"
                  class="mr-4"
                  @click="renew"
                >
                  <span class="cursor-pointer text-red-700 transition-all duration-500 hover:text-red-500">Membership expired</span>
                </span>
                <span>
                  <span v-if="!disablePoints">
                    {{ homePoints }} point<span v-if="homePoints !== 1">s</span> remaining
                  </span>
                  <span v-else>
                    {{ homePoints }} round<span v-if="homePoints !== 1">s</span> remaining
                  </span>
                </span>
              </span>
            </div>
          </li>

          <div
            v-show="!summaryLoading && !enableSqueeze"
            class="hidden h-3 w-3 cursor-pointer text-gray-800 hover:text-gray-900 sm:block"
            style="margin-top: 2px;"
            @click="refreshPoints"
          >
            <svg
              :class="pointsLoading?'rotate':''"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="sync-alt"
              role="img"
              viewBox="0 0 512 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M8 454.06V320a24 24 0 0 1 24-24h134.06c21.38 0 32.09 25.85 17 41l-41.75 41.75A166.82 166.82 0 0 0 256.16 424c77.41-.07 144.31-53.14 162.78-126.85a12 12 0 0 1 11.65-9.15h57.31a12 12 0 0 1 11.81 14.18C478.07 417.08 377.19 504 256 504a247.14 247.14 0 0 1-171.31-68.69L49 471c-15.15 15.15-41 4.44-41-16.94z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M12.3 209.82C33.93 94.92 134.81 8 256 8a247.14 247.14 0 0 1 171.31 68.69L463 41c15.12-15.12 41-4.41 41 17v134a24 24 0 0 1-24 24H345.94c-21.38 0-32.09-25.85-17-41l41.75-41.75A166.8 166.8 0 0 0 255.85 88c-77.46.07-144.33 53.18-162.79 126.85A12 12 0 0 1 81.41 224H24.1a12 12 0 0 1-11.8-14.18z"
            /></g></svg>
          </div>

          <li
            v-if="!summaryLoading"
            class="hidden select-none sm:block"
          >
            <div
              v-if="!IsImpersonating"
              class="mr-5 text-base"
            >
              <span class="mx-5 hover:text-gray-900">{{ username }}</span>
            </div>
            <div
              v-else
              class="mr-5 text-base"
            >
              <span class="mx-5 text-red-700 hover:text-red-800">You are currently impersonating {{ username }}</span>
            </div>
          </li>

          <li v-if="basket === null && enableSqueeze || basket === undefined && enableSqueeze">
            <svg
              style="width: 0.05rem; height: 1.225rem;"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="shopping-basket"
              class="fas fa-shopping-basket -mt-1 inline-block cursor-pointer select-none text-xl opacity-0 sm:text-lg"
              role="img"
              viewBox="0 0 576 512"
            ><path
              fill="currentColor"
              d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"
            /></svg>
          </li>

          <li v-if="basket !== null && basket !== undefined">
            <div
              class="mr-5 inline-block"
            >
              <div class="relative inline-block">
                <div @click="switchBasketMenuState">
                  <svg
                    style="width: 1.225rem; height: 1.225rem;"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="shopping-basket"
                    class="fas fa-shopping-basket -mt-1 inline-block cursor-pointer select-none text-xl text-gray-700 hover:text-gray-900 sm:text-lg"
                    role="img"
                    viewBox="0 0 576 512"
                  ><path
                    fill="currentColor"
                    d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"
                  /></svg>
                  <div
                    class="absolute inline-block cursor-pointer select-none bg-red-700 text-xs leading-none text-white"
                    style="top: 25px; right: -10px; padding: 1px 5px; border-radius: 50%;"
                  >1</div>
                </div>
                <BasketMenu
                  v-if="openBasketMenu"
                  :enable-squeeze="enableSqueeze"
                />
                <div
                  v-if="openBasketMenu"
                  class="fixed left-0 top-0 h-screen w-screen cursor-default"
                  @click="switchBasketMenuState"
                />
              </div>
            </div>
          </li>

          <li v-show="!enableSqueeze && !hasSpa">
            <router-link :to="'/' + currentTheme.buddiesTextPlural">
              <div class="mr-5 inline-block cursor-pointer">
                <div class="relative inline-block  text-base">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fad"
                    data-icon="user-friends"
                    class="-mt-1 inline-block h-6 w-6 text-xl leading-none text-gray-700 hover:text-gray-900 sm:text-lg"
                    role="img"
                    viewBox="0 0 640 512"
                  ><g class="fa-group"><path
                    class="fa-secondary"
                    fill="currentColor"
                    d="M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z"
                    opacity="0.4"
                  /><path
                    class="fa-primary"
                    fill="currentColor"
                    d="M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z"
                  /></g></svg>
                  <div
                    v-if="buddyRequests > 0"
                    class="absolute inline-block bg-red-700 text-xs leading-none text-white"
                    style="top: 25px; right: -10px; padding: 1px 5px; border-radius: 50%;"
                  >{{ buddyRequests }}</div>
                </div>
              </div>
            </router-link>
          </li>
          <li v-show="!enableSqueeze">
            <div
              class="mr-5 inline-block"
              @click="openNotifications"
            >
              <div class="relative inline-block">
                <div
                  class="inline-block w-4 cursor-pointer select-none text-gray-700 hover:text-gray-900"
                >
                  <div
                    :class="openNotificationMenu?'bellIcon':''"
                    class="h-4 w-4"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="bell"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    ><path
                      fill="currentColor"
                      d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                    /></svg>
                  </div>
                </div>
                <div
                  v-if="notificationCount > 0"
                  class="absolute inline-block cursor-pointer select-none bg-red-700 text-xs leading-none text-white"
                  style="top: 25px; right: -10px; padding: 1px 5px; border-radius: 50%;"
                >{{ notificationCount }}</div>
                <NotificationMenu
                  v-if="openNotificationMenu"
                  :notifications="notifications"
                  @click="openNotifications()"
                />


              </div>
            </div>
            <div
              v-if="openNotificationMenu"
              class="fixed left-0 top-0 h-screen w-screen  cursor-default"
              @click="openNotificationMenu = false"
            />
          </li>
          <li v-if="isAuthenticated">
            <div>
              <div
                class="ml-0 mr-8 flex cursor-pointer items-center text-lg"
                @click="openLogoutConfirmModal = true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fad"
                  data-icon="sign-out-alt"
                  :class="IsImpersonating?'text-red-700 hover:text-red-800':''"
                  class="-mt-1 h-5 w-5 text-xl hover:text-gray-900 sm:text-lg"
                  role="img"
                  viewBox="0 0 512 512"
                ><g class="fa-group"><path
                  class="fa-secondary"
                  fill="currentColor"
                  d="M64 160v192a32 32 0 0 0 32 32h84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H96a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H96a32 32 0 0 0-32 32z"
                  opacity="0.4"
                /><path
                  class="fa-primary"
                  fill="currentColor"
                  d="M288 424v-96H152a23.94 23.94 0 0 1-24-24v-96a23.94 23.94 0 0 1 24-24h136V88c0-21.4 25.9-32 41-17l168 168a24.2 24.2 0 0 1 0 34L329 441c-15 15-41 4.52-41-17z"
                /></g></svg>
              </div>
            </div>
          </li>
          <li v-else>
            <div>
              <router-link :to="'/login/' + club.clubName + '/' + currentTheme.clubId ">
                <span class="font-brand ml-0 mr-8 text-base hover:text-gray-900">
                  Login
                </span>
              </router-link>
            </div>
          </li>
        </span>
      </ul>
    </div>

    <transition name="fade">
      <NotificationPopup
        v-if="openNotificationPopup"
        :notification="notificationPopup"
        @click="closePopup"
        @acceptBuddy="acceptBuddy"
        @declineBuddy="declineBuddy"
      />
    </transition>

    <AcceptBuddy
      v-if="openAcceptBuddy"
      :buddy="buddy"
      @closeAcceptBuddy="closeAcceptBuddy"
    />

    <DeclineBuddy
      v-if="openDeclineBuddy"
      :buddy="buddy"
      @closeDeclineBuddy="closeDeclineBuddy"
    />

    <WarningConfirmModal
      v-if="openLogoutConfirmModal"
      :title="'Logout'"
      :message="'Are you sure you want to logout?'"
      @result="logoutResult"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Notification } from 'pmg-services';
import { hubConnection } from 'signalr-no-jquery';
import moment from 'moment/moment';
import WarningConfirmModal from '@/app/shared/components/base/modal/warningConfirmModal.vue';
import NotificationMenu from '@/app/shared/components/notification/notificationMenu.vue';
import BasketMenu from '@/app/shared/components/basket/basketMenu.vue';
import NotificationPopup from '@/app/shared/components/notification/notificationPopup.vue';
import AcceptBuddy from '../../buddy/acceptBuddy.vue';
import DeclineBuddy from '../../buddy/declineBuddy.vue';
import services from '@/app/shared/services';
import router from '@/app/app-routes';
import { environment, nodeEnv } from '@/environment/environment';
import GNMenu from '../../../../../assets/gnmenu';

export default {

  components: {
    BasketMenu,
    NotificationMenu,
    NotificationPopup,
    AcceptBuddy,
    DeclineBuddy,
    WarningConfirmModal,
  },

  props: {
    hideHomeClub: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: undefined,
      availableThemes: [],
      currentThemeId: 0,
      buddyRequests: 0,
      notificationCount: 0,
      notifications: undefined,
      buddy: undefined,
      environment: undefined,

      openNotificationMenu: false,
      openNotificationPopup: false,
      openAcceptBuddy: false,
      openDeclineBuddy: false,
      openLogoutConfirmModal: false,

      notificationPopup: {
        type: 1,
        action: 0,
        object: {
          Title: 'Trophy Unlocked',
          Description: 'You have unlocked the Enthusiast trophy',
        },
      },
    };
  },

  computed: {
    ...mapState({
      homePoints: state => state.user.homePoints,
      flexiPoints: state => state.user.flexiPoints,
      pointsLoading: state => state.user.refreshingPoints,
      summaryLoading: state => state.user.refreshingMemberSummary,
      username: state => state.user.username,
      isAuthenticated: state => state.user.isAuthenticated,
      IsImpersonating: state => state.user.IsImpersonating,
      IsExpired: state => state.user.IsExpired,
      userGuid: state => state.user.userGuid,
      basket: state => state.basket.basket,
      openBasketMenu: state => state.basket.openBasketMenu,
      squeezePage: state => state.theme.squeezePage,
      completedFirstPayment: state => state.user.completedFirstPayment,
      forename: state => state.user.forename,
      surname: state => state.user.surname,
      homeClubId: state => state.user.homeClubId,
      homeClubDetails: state => state.club.homeClubDetails,
      additionalBookers: state => state.club.additionalBookers,
    }),

    courses() {
      return this.homeClubDetails !== null ? this.homeClubDetails.Courses : null;
    },

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      disablePoints: 'disablePoints',
      club: 'getClub',
      hasSpa: 'hasSpa',
      hasGolf: 'hasGolf',
      miscId: 'miscId',
      hasRestaurant: 'hasRestaurant',
    }),

    enableSqueeze() {
      if (!this.squeezePage || this.completedFirstPayment) {
        return false;
      }
      return true;
    },
  },

  watch: {
    enableSqueeze(value) {
      if (!value) {
        this.initialize();
      }
    },

    menu: {
      handler(val) {
        if (val !== undefined) {
          this.$emit('sidebarClick', val.isMenuOpen);
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.environment = nodeEnv;
    if (!this.enableSqueeze) {
      this.initialize();
    }

    if (this.isAuthenticated) {
      this.getNumberOfBuddyRequests();
      this.getNumberOfUnreadNotifications();
      this.initializeSignalR();
    }
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getBookingLink() {
      if (this.homeClubDetails !== null && this.courses.length > 0) {
        return `/booking/clubs/${this.homeClubId}/${this.courses.filter(c => c.FacilityType === 0)[0].Id}?Date=${new moment().toISOString()}&Players=1&Juniors=0`;
      }

      return '';
    },

    spa() {
      this.$router.push(`/spa/${this.homeClubId}/${this.courses.filter(c => c.FacilityType === 1)[0].Id}`);
    },

    dining() {
      this.$router.push(`/dining/${this.homeClubId}/${this.courses.filter(c => c.FacilityType === 4)[0].Id}`);
    },

    additional() {
      const booker = JSON.parse(JSON.stringify(this.additionalBookers)).filter(b => b.Title === 'Personal Training & Guest Passes')[0];
      console.log(this.additionalBookers);
      this.$router.push({
        name: 'ecomSearchAdditional',
        params: {
          type: booker.Category, clubId: this.homeClubId, courseId: this.miscId, title: booker.DescriptiveTitle, description: booker.Description,
        },
      });
    },

    renew() {
      window.location.href = '/Renew';
    },

    getClubLink() {
      if (this.isAuthenticated) {
        return '/';
      }
      return `/Club/TheGrove/${this.club.clubId}`;
    },

    logoutResult(result) {
      if (result) {
        this.logout();
      } else {
        this.openLogoutConfirmModal = false;
      }
    },

    initialize() {
      this.menu = new GNMenu();
    },

    logout() {
      if (!this.IsImpersonating) {
        services.account.logout().then(() => {
          const homeClubName = this.$store.state.user.homeClubName;
          const homeClubId = this.$store.state.user.homeClubId;
          this.$store.commit('toggleHideGlobalLoader', true);
          this.$store.dispatch('setAuth', false);
          this.$store.dispatch('emptyMemberSummary');

          const loginPath = `/login/${homeClubName}/${homeClubId}`;
          router.push(`${loginPath}`);
        });
      } else {
        services.account.stopImpersonating().then(() => {
          if (process.env.NODE_ENV === 'production') {
            const url = this.homeClubId === 523 ? `https://dashboard.xviii.golf/member/${this.userGuid}` : `${environment}/#!/memberInfo?guid=${this.userGuid}`;
            this.$store.dispatch('setAuth', false);
            this.$store.dispatch('emptyMemberSummary');
            window.location.replace(url);
          } else {
            const url = this.homeClubId === 523 ? `https://localhost:3000/member/${this.userGuid}` : `${environment}/#!/memberInfo?guid=${this.userGuid}`;
            this.$store.dispatch('setAuth', false);
            this.$store.dispatch('emptyMemberSummary');
            window.location.replace(url);
          }

          // if (!data) {
          //   services.account.logout().then(() => {
          //     const homeClubName = this.$store.state.user.homeClubName;
          //     const homeClubId = this.$store.state.user.homeClubId;

          //     this.$store.commit('toggleHideGlobalLoader', true);
          //     this.$store.dispatch('setAuth', false);
          //     this.$store.dispatch('emptyMemberSummary');

          //     const loginPath = `/login/${homeClubName}/${homeClubId}`;
          //     router.push(`${loginPath}`);
          //   });
          // }
        });
      }
    },

    refreshPoints() {
      this.$store.dispatch('refreshPoints', true);
    },

    switchBasketMenuState() {
      this.$store.dispatch('switchBasketMenuState');
    },

    openNotifications() {
      this.openNotificationMenu = !this.openNotificationMenu;
      this.openNotificationPopup = false;

      if (this.notificationCount > 0) {
        this.notificationCount = 0;
        services.notification.allNotificationsAsRead();
      }
    },

    getNumberOfBuddyRequests() {
      services.buddy.buddyRequestCount().then((data) => {
        this.buddyRequests = data;
      });
    },

    getNumberOfUnreadNotifications() {
      services.notification.unreadNotificationCount().then((data) => {
        this.notificationCount = data;
      });
    },

    initializeSignalR() {
      const connection = hubConnection(`${environment}/signalr/notificationHub`);
      const hubProxy = connection.createHubProxy('notificationHub');

      const vm = this;
      hubProxy.on('addNewMessageToPage', (title, desc, type, action, id) => {
        const notification = new Notification(id, null, false, null, null, type, action, { Title: title, Description: desc });
        vm.notificationReceived(notification);
      });

      connection.start();
    },

    notificationReceived(notification) {
      if (Notification.type !== 1) {
        this.notificationCount += 1;
        this.notificationPopup = notification;
        this.openNotificationPopup = true;

        setTimeout(() => {
          this.openNotificationPopup = false;
        }, 10000);
      }
    },

    closePopup() {
      this.openNotificationPopup = false;
    },

    acceptBuddy(id) {
      // Ew, fix
      const nameSplit = this.notificationPopup.object.Description.split(' ');
      this.buddy = { target: id, name: `${nameSplit[0]} ${nameSplit[1]}` };
      this.openAcceptBuddy = true;
    },

    declineBuddy(id) {
      // Same here
      const nameSplit = this.notificationPopup.object.Description.split(' ');
      this.buddy = { target: id, name: `${nameSplit[0]} ${nameSplit[1]}` };
      this.openDeclineBuddy = true;
    },

    closeAcceptBuddy() {
      this.openAcceptBuddy = false;
    },

    closeDeclineBuddy() {
      this.openDeclineBuddy = false;
    },
  },

};

</script>


<style>
  .rotate {
    animation: rotation 1s infinite linear;
  }

  @keyframes rotation {
    0% {
      transform: rotate(-360deg);
    }
  }

  @media (max-width: 768px){
    .bellIcon:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-top: 5px;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
  }

}
</style>
