<template>
  <div
    class="buddyCardBg relative z-10 mx-auto max-w-5xl xs:-mt-24 xs:shadow-lg"
  >
    <div class="w-full min-w-full xxs:flex">
      <div
        class="hidden h-24 w-full select-none items-center justify-center bg-brand-500 object-cover align-top shadow-md xxs:h-32 xxs:w-auto xs:flex xs:h-40"
      >
        <div
          v-if="forename !== undefined"
          class="defaultFont mx-12 mb-1 text-6xl font-thin text-white xs:mx-24"
        >
          {{ forename.charAt(0) }}{{ surname.charAt(0) }}
        </div>
      </div>

      <div class="mx-2 flex-grow p-6 xs:inline-block xs:p-4">
        <div class="flex items-center justify-between">
          <div
            v-if="forename !== undefined"
            class="brand-heading inline-block text-center text-2xl tracking-wide text-gray-800 xxs:text-left"
          >
            {{ forename }} {{ surname }}
          </div>

          <!-- <div class="inline-block text-sm xs:text-base">
            <div class="text-gray-600 uppercase tracking-widest defaultFont">
              <div class="inline text-brand-800">
                &bull;
              </div>
              Premium
            </div>
          </div> -->
        </div>

        <transition name="fade">
          <div
            v-if="shareToken !== undefined"
            class="defaultFont"
          >
            <div class="mt-4 text-sm uppercase tracking-widest text-gray-600">
              Your {{ currentTheme.buddiesText }} share code
            </div>

            <div class="flex items-center">
              <div class="inline-block text-2xl tracking-wider text-gray-800">
                {{ shareToken }}
              </div>
              <div
                class="group ml-2 inline-block cursor-pointer rounded-full border bg-white text-gray-800 hover:bg-gray-100 hover:text-gray-700"
                style="padding: 2px 7px; border-radius: 50%;"
                @click="copyCodeToClipboard"
              >
                <svg
                  v-if="!copied"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="copy"
                  class="h-4 w-4"
                  style="margin-top: 3px; margin-bottom: 3px"
                  role="img"
                  viewBox="0 0 448 512"
                ><path
                  fill="currentColor"
                  d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"
                /></svg>
                <svg
                  v-if="copied"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="check"
                  class="h-4 w-4 text-green-600"
                  style="margin-top: 3px; margin-bottom: 3px"
                  role="img"
                  viewBox="0 0 512 512"
                ><path
                  fill="currentColor"
                  d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
                /></svg>
              </div>

              <div
                class="group ml-1 inline-block cursor-pointer rounded-full border bg-white text-gray-800 hover:bg-gray-100 hover:text-gray-700"
                style="padding: 2px 11px; border-radius: 50%;"
                @click="openShareCodeInfo = true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="info"
                  class="h-4 w-2"
                  style="margin-top: 3px; margin-bottom: 3px;"
                  role="img"
                  viewBox="0 0 192 512"
                ><path
                  fill="currentColor"
                  d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"
                /></svg>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <ShareCodeInfo v-if="openShareCodeInfo" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import services from '@/app/shared/services';
import ShareCodeInfo from './shareCodeInfo.vue';

export default {

  components: {
    ShareCodeInfo,
  },

  data() {
    return {
      shareToken: undefined,
      copied: false,
      openShareCodeInfo: false,
    };
  },

  computed: {
    ...mapState({
      forename: state => state.user.forename,
      surname: state => state.user.surname,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  mounted() {
    services.buddy.buddyShareToken().then((result) => {
      this.shareToken = result;
    });
  },

  methods: {
    copyCodeToClipboard() {
      const textArea = document.createElement('textarea');

      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = this.shareToken;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        // const msg = successful ? 'successful' : 'unsuccessful';
        this.copied = true;
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);
    },
  },


};


</script>

<style>
  @media (min-width: 640px){
    .buddyCardBg {
      background-image: linear-gradient(rgba(255, 255, 255, 0.84),rgba(255,255,255,0.98), rgba(255,255,255,1))
    }
  }

  @media (max-width: 640px){
    .buddyCardBg {
      background-color: rgb(243, 243, 243)
    }
  }

</style>
