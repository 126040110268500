<template>
  <div
    class="relative z-10 mx-auto max-w-5xl shadow-lg xs:-my-32"
    style="background-image: linear-gradient(rgba(255, 255, 255, 0.84),rgba(255,255,255,0.98), rgba(255,255,255,1))"
  >
    <div class="w-full min-w-full xxs:flex">
      <div
        class="hidden h-24 w-full select-none items-center justify-center bg-brand-500 object-cover align-top xxs:flex xxs:h-32 xxs:w-auto xs:h-36 sm:h-40"
      >
        <!-- <div
          v-if="forename !== undefined"
          class="mx-12 mb-1 text-6xl font-thin text-white xs:mx-24"
        >
          {{ forename.charAt(0) }}{{ surname.charAt(0) }}
        </div> -->
        <img
          class="mx-12 h-28 xs:mx-32"
          :src="currentTheme.memberCardLogo"
        >
      </div>

      <div class="flex h-24 w-full flex-col justify-between border border-b-0 border-l-0 xs:h-36 sm:h-40">
        <div class="flex h-full flex-col justify-between p-5 px-6">
          <div class="flex items-center justify-between">
            <div
              v-if="forename !== undefined"
              class="brand-heading color inline-block text-xl leading-none tracking-wide xs:text-2xl sm:text-2xl md:text-2xl"
            >
              {{ forename }} {{ surname }}
            </div>

            <div
              v-if="packageName !== undefined && packageName !== null"
              class="mr-2 inline-block text-sm leading-none xs:text-base"
            >
              <div class="uppercase leading-none tracking-widest text-gray-600">
                <div class="inline leading-none text-brand-800">
                  &bull;
                </div>
                {{ packageName }}
              </div>
            </div>

            <div
              v-else
            >
              <div
                v-if="!isExpired && completedFirstPayment"
                class="-mt-2 text-sm xs:text-base"
              >
                <div class="-mt-2 flex uppercase leading-none tracking-widest text-gray-600">
                  <div class="mr-1 leading-none text-brand-800">
                    &bull;
                  </div>
                  Member
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="homeClubName !== undefined"
            class="brand-heading text-lg tracking-wider text-gray-600 xs:text-xl md:text-2xl"
          >
            <!-- {{ homeClubName }} -->
            <!-- <img
            v-if="!hasSpa"
            class="h-12"
            :src="currentTheme.loginLogoAlternative"
          > -->

            <div

              class="flex items-center"
            >
              <div class="text-primary-500 brand-heading text-lg">
                {{ club.clubName }}
              </div>
            </div>
          </div>

          <div
            v-if="!isExpired"
            class="brand-body text-xs text-green-700 xs:text-sm sm:text-lg"
          >
            Renewal due on {{ membershipExpires | moment("dddd, Do MMMM YYYY") }} at {{ membershipExpires | moment("h:mma") }}
          </div>

          <div
            v-else
            class="brand-body text-xs text-red-700 xs:text-sm sm:text-lg"
          >
            Your membership has expired, please <div
              class="inline-block cursor-pointer select-none transition-all duration-500 hover:text-red-500"
              @click="renew()"
            >
              renew
            </div> to continue your membership.
          </div>
        <!--
        <div class="xs:mt-2 text-xs xs:text-sm sm:text-base">
          <i
            v-for="(index) in renewalStars"
            :key="index"
            class="fas fa-star text-brand-800"
          />
          <i
            v-for="(index) in starsRemaining"
            :key="index + 100"
            class="far fa-star"
          />
        </div> -->
        </div>
      </div>
    </div>

    <div
      v-if="membershipPercent > 0"
      class="flex w-full min-w-full bg-gray-200"
    >
      <div
        class="z-0 h-2 border-gray-200 bg-gradient-to-r opacity-75"
        :class="getMembershipColor()"
        :style="getMembershipWidth"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {

  data() {
    return {
      renewalStars: 1,
    };
  },

  computed: {

    ...mapState({
      forename: state => state.user.forename,
      surname: state => state.user.surname,
      homeClubName: state => state.user.homeClubName,
      packageName: state => state.user.packageName,
      membershipExpires: state => state.user.membershipExpires,
      isExpired: state => state.user.IsExpired,
      completedFirstPayment: state => state.user.completedFirstPayment,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
      hasSpa: 'hasSpa',
      expiry: 'getMemberExpiryInfo',
    }),


    membershipPercent() {
      return 50;
      // return this.expiry.percentageOfMembershipLeft();
    },

    getMembershipWidth() {
      return `width: ${this.membershipPercent}%;`;
    },

    starsRemaining() {
      if (this.renewalStars <= 5) {
        return 5 - this.renewalStars;
      }
      return 0;
    },
  },

  methods: {
    getMembershipColor() {
      if (this.membershipPercent > 20) {
        return 'from-brand-300 to-brand-500';
      }
      if (this.membershipPercent > 10) {
        return 'from-yellow-200 to-yellow-300';
      }
      return 'from-red-400 to-red-500';
    },

    renew() {
      this.$emit('showView', 'Renew');
    },
  },
};


</script>
