<template>
  <div>
    <div
      v-if="availablePackages !== undefined && availablePackages !== null"
      class="mx-auto mt-6 max-w-5xl text-gray-800"
    >
      <div
        class="overflow-hidden bg-cover bg-center pt-8 text-gray-400 sm:pt-16"
        :style="'background-image: linear-gradient(rgba(21,26,47,0.2),rgba(21, 26, 47, 0.5)),url(' + currentTheme.topup.header + ');'"
      >
        <div
          class="brand-heading px-10 pb-12 pt-2 text-2xl text-gray-200 xs:text-3xl sm:pb-20 md:text-4xl"
        >
          {{ club.topup.title }}
        </div>
      </div>

      <div class="">
        <div class="flex justify-center pb-16 pt-8 xs:pt-16 ">
          <img
            class="hidden w-6/12 object-cover xs:inline-block"
            :class="availablePackages.HomePackages.length <= 2 ? 'w-8/12' : 'w-6/12'"
            :src="currentTheme.topup.body"
            style="height: 490px;"
          >

          <div
            class="background ml-0 border-gray-300 p-4 shadow-md xs:m-24 xs:-ml-32 xs:mr-0 xs:mt-20 xs:border-none xs:p-10 xs:pr-0 xs:shadow-none md:border"
          >
            <div class="brand-heading border border-gray-200 p-4 text-xl text-gray-800 xs:border-none xs:p-0 xs:text-2xl">
              <div v-if="!disablePoints">
                <span class="text-2xl text-brand-800 xs:text-3xl">A</span>dditional points can be spent on rounds of golf at {{ homeClubName }}. Any points remaining can be carried over to next year's renewal.
              </div>
              <div v-else>
                <span class="text-2xl text-brand-800 xs:text-3xl">A</span>dditional rounds can be purchased for more golf at {{ homeClubName }}. Any rounds remaining can be carried over to next year's renewal.
              </div>

              <div class="-ml-2 mr-2 mt-4 flex flex-col space-y-4 md:flex-row md:space-y-0">
                <TopUpCard
                  v-for="homePackage in availablePackages.HomePackages"
                  :key="homePackage.BundleId"
                  :club-package="homePackage"
                  :promocode="promocode"
                  :home-club-id="homeClubId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="mb-4 mt-8 w-full min-w-full"
    >
      <div class="mx-auto text-center">
        <LoadingSpinner />
        <div class="brand-body mt-2 text-sm">
          Loading the topup packages
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

import { nodeEnv } from '@/environment/environment';
import LoadingSpinner from '../../base/loadingSpinner.vue';
import TopUpCard from './topUpCard.vue';

export default {

  components: {
    LoadingSpinner,
    TopUpCard,
  },

  props: {
    availablePackages: {
      type: Object,
      default: undefined,
    },
  },


  data() {
    return {
      promocode: '',

      environment: undefined,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      homeClubId: state => state.user.homeClubId,
      homeClubName: state => state.user.homeClubName,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
      disablePoints: 'disablePoints',
    }),
  },

  mounted() {
    this.environment = nodeEnv;
  },

  methods: {
    getBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url(${process.env.VUE_APP_BASE_URL}'img/thegrove7.jpg'); background-size: cover; background-position: center;`;
    },

  },
};
</script>

<style>
  @media (min-width: 640px){
    .background {
      background-color: #f3f3f3;
    }
  }

  @media (max-width: 640px){
    .background {
      background-color: #ffffff;
    }
  }
</style>
