<template>
  <div
    v-if="notification.type !== 1"
    class="pointer-events-none fixed inset-0 mt-16 flex items-end justify-center px-4 py-6 sm:items-start sm:justify-end sm:p-6"
  >
    <div class="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg">
      <div class="shadow-xs overflow-hidden rounded-lg">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <div class="h-6 w-6 text-gray-800">
                <i :class="getIcon()" />
              </div>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <router-link :to="getRoute()">
                <p class="text-sm font-medium leading-5 text-gray-900">
                  {{ notification.object.Title }}
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-600">
                  {{ notification.object.Description }}
                </p>
              </router-link>
              <div
                v-if="notification.type === 2"
                class="mt-2 flex text-sm font-medium"
              >
                <div
                  class="cursor-pointer text-green-700"
                  @click="acceptBuddy()"
                >
                  Accept
                </div>
                <div
                  class="ml-6 cursor-pointer text-red-700"
                  @click="declineBuddy()"
                >
                  Decline
                </div>
              </div>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button
                class="inline-flex text-gray-400 transition duration-150 ease-in-out hover:text-gray-800 focus:text-gray-500 focus:outline-none"
                @click="closePopup()"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  components: {

  },

  props: {
    notification: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {

    };
  },

  methods: {
    getIcon() {
      switch (this.notification.type) {
        case 0:
          return 'fad fa-calendar-day';
        case 1:
          return 'fad fa-trophy';
        case 2:
          return 'fad fa-user-friends';
        case 3:
          return 'fad fa-user';
        case 4:
          return 'fad fa-ticket-alt';
        case 5:
          return 'fad fa-shekel-sign';
        default:
          return 'fad fa-info';
      }
    },

    getRoute() {
      switch (this.notification.type) {
        case 0:
          return '/';
        case 1:
          return '/Trophies';
        case 2:
          return '/Buddies';
        case 3:
          return '/Account';
        case 4:
          return '/Account';
        case 5:
          return '';
        default:
          return '';
      }
    },

    closePopup() {
      this.$emit('click', false);
    },

    acceptBuddy() {
      this.$emit('acceptBuddy', this.notification.id);
      this.$emit('click', false);
    },

    declineBuddy() {
      this.$emit('declineBuddy', this.notification.id);
      this.$emit('click', false);
    },
  },

};


</script>
