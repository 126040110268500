<template>
  <div
    class="brand-button bg-white text-center text-sm text-gray-800"
    style="z-index: 20;"
  >
    <div
      class="transition-300 inline-block w-1/5 p-2 transition-all"
      :class="isCurrentView('Home')?'bg-brand-500 text-white':''"
      @click="changeView('/', 'Home')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="mt-1 leading-none">
        Home
      </div>
    </div>

    <div
      class="transition-300 inline-block w-1/5 p-2 transition-all"
      :class="isCurrentView('Book')?'bg-brand-500 text-white':''"
      @click="changeView('/booking/clubs/' + homeClubId, 'Book')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="mt-1 leading-none">
        Book
      </div>
    </div>

    <div
      class="transition-300 inline-block w-1/5 p-2 transition-all"
      :class="isCurrentView('Buddies')?'bg-brand-500 text-white':''"
      @click="changeView('/' + currentTheme.buddiesTextPlural, 'Buddies')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="mt-1 leading-none">
        {{ currentTheme.buddiesTextPlural }}
      </div>
    </div>

    <div
      class="transition-300 inline-block w-1/5 p-2 transition-all"
      :class="isCurrentView('Account')?'bg-brand-500 text-white':''"
      @click="changeView('/account/profile', 'Account')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="mt-1 leading-none">
        Account
      </div>
    </div>

    <div
      class="transition-300 inline-block w-1/5 p-2 transition-all"
      :class="isCurrentView('Help')?'bg-brand-500 text-white':''"
      @click="changeView('/Help', 'Help')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="mt-1 leading-none">
        Help
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../../app-routes';
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      currentView: 'Home',
    };
  },

  computed: {
    ...mapState({
      homeClubId: state => state.user.homeClubId,
      isAuthenticated: state => state.user.isAuthenticated,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  watch: {
    $route(to) {
      if (to.path === '/') {
        this.currentView = 'Home';
      } else if (to.path.includes('booking')) {
        this.currentView = 'Book';
      } else if (to.path.includes('Buddies' || to.path.includes(this.currentTheme.buddiesTextPlural))) {
        this.currentView = 'Buddies';
      } else if (to.path.includes('account')) {
        this.currentView = 'Account';
      } else if (to.path.includes('Help')) {
        this.currentView = 'Help';
      }
    },
  },

  created() {
    const { path } = this.$router.currentRoute;

    if (path === '/') {
      this.currentView = 'Home';
    } else if (path.includes('booking')) {
      this.currentView = 'Book';
    } else if (path.includes('Buddies') || path.includes(this.currentTheme.buddiesTextPlural)) {
      this.currentView = 'Buddies';
    } else if (path.includes('account')) {
      this.currentView = 'Account';
    } else if (path.includes('Help')) {
      this.currentView = 'Help';
    }
  },

  methods: {
    isCurrentView(view) {
      if (view === this.currentView) {
        return true;
      }
      return false;
    },

    changeView(route, view) {
      if (this.$router.currentRoute.path !== route) {
        this.currentView = view;
        router.push(route);
      }
    },
  },
};
</script>
