import preset from '@bbob/preset-vue';
import localImg from './bbLocalImgComponent.vue';

const ourBbcodePreset = preset.extend(tags => ({
  ...tags,
  localimg: node => ({
    tag: localImg,
    attrs: {
      title: node.content,
      class: node.attrs.class,
    },
  }),
}));

export default ourBbcodePreset;
