import { Club } from '../classes';

// hacky and maybe not needed
const defaultClub = new Club({
  clubId: 0,
  clubName: 'Golf Club Name',
});

const stokePark = new Club({
  clubId: 563,
  clubName: 'Stoke Park',
  clubNameShort: 'Stoke Park',
  disableCheckout: false,
  disablePoints: false,
  telephone: '01753 260408',
  mapsLink: 'https://maps.app.goo.gl/m6mKU7P4V98ztTVg8',
  address: ['Stoke Park', 'Park Road, Stoke Poges', 'Buckinghamshire SL2 4PG'],
  website: 'https://stokepark.com/',
  loginText: '',
  registerText: 'Purchase a Corinthian Collection package',
  email: 'stokepark@xviii.golf',
  notesTitle: 'Guest Names',
  notesRequired: true,
  homeWidgets: [
    { title: 'Tee Times at Stoke Park', type: 'Golf' },
  ],
  dashboard: {
    message: 'Utilise your rounds to play golf on our Harry Colt Championship golf course. Invite friends, colleagues, clients and customers.',
    teeTimesMessage: 'Stoke Park Tee Times',
  },
  introduction: 'A unique opportunity to enjoy one of the finest Parkland courses in the UK. With 15-minute intervals between tee times and a limited number of rounds available each day you and your guests can enjoy a relaxed round in a private environment. Guests will be met on arrival by the golf team and transferred to the golf academy to warm-up before play. Whilst the refurbishment of the estate continues off-course facilities are limited to the golf academy. Please arrive ready to play. There are no changing / locker room or clubhouse facilities available.',
  termsAndConditions: [
    'By purchasing one of our Corinthian Golf packages, you agree to our standard terms and conditions as well as the following specific terms and conditions.',
    'These terms and conditions apply to all purchases of our Corinthian Golf package and bookings made with us (Stoke Park), whether online, by email, by telephone or in person. Please read them carefully before purchasing a package and making a booking. ',
    'Stoke Park is a trading name of Stoke Park Limited, a company registered in England and Wales with company number 00529476 and registered office at Stoke Park, Park Road, Stoke Poges, Buckinghamshire, SL2 4PG. ',
    'When you purchase a Corinthian Golf package and make bookings with Stoke Park to play rounds of golf, you enter into a contract with Stoke Park Limited on the basis of these terms and conditions.',
    'You (the person purchasing the Corinthian Golf package and making the booking) must be at least 18 years old and must have the authority to accept these terms and conditions on behalf of all golfers in your party. ',
    'Stoke Park reserves the right to amend these terms and conditions at any time without prior notice. The terms and conditions that apply to your account and bookings are those that are in force on the date of purchase and booking. ',
    'You can register to purchase a Corinthian Golf Packages with Stoke Park online. Stoke Park will then provide you with confirmation of your acceptance to purchase a Corinthian Golf Package and provide you with details of the web address to purchase. ',
    'Stoke Park reserves the right to decline applications to purchase a Corinthian Account Package if it feels individual’s will be disruptive to other golfers or members of staff.  Stoke Park’s decision is final. ',
    'All prices are inclusive of VAT at the current rate and are subject to change without prior notice. Stoke Park reserves the right to charge a surcharge or refund a difference in the event of a change in the VAT rate. ',
    'A £100 administration fee will be charged on all packages upon signing. ',
    'Packages are valid for 12 months only.',

    'Any unused home points will be carried over upon renewal into the following year. ',

    'If you choose not to renew into the following year, any unused points will be lost. ',

    'Bookings should be made online through your individual account on our Corinthian Golf portal.',

    'Bookings can be made up to 14 days in advance.',

    'Bookings can be made on any available tee-time, seven days a week.',

    'Stoke Park will send you a booking confirmation by email, which will include your booking reference number and details of your booking. Please check this carefully and contact us immediately if there are any errors or discrepancies.',

    'All bookings incur deduction of the appropriate points for your round of golf at the point of booking.',

    'All bookings will be made using points only.  Any additional guests will be charged using your points balance.  Should you have insufficient points you can top up your points balance to make a booking.',

    'Cancellations must be made online through your individual account on our Corinthian Golf portal. ',

    'Cancellations cannot be made within 48 hours of the tee-time, unless approved by Golf Management. ',

    'Account Holders can make 1 booking per day under the Lane Jackson package, 2 bookings per day using the Alison package and 3 bookings per day using the Colt Package.',

    'An account Holder must be present when bookings are utilised.',

    'All purchases online purchases are eligible for a 14 day cooling off period and refundable net of the points cost of any bookings incurred between purchase and cancellation.  After 14 days your purchase is not refundable and points are not transferrable.',

    'Stoke Park reserves the right to cancel or amend your booking in the event of circumstances beyond its reasonable control, such as fire, flood, strike, pandemic, closure or any other force majeure event. In such cases, Stoke Park will re credit booked points to your account.',
    'Stoke Park reserves the right to close the course for exclusive hire during the tenure of your account. This may last in excess of 6 weeks.  Course closure will be a regular occurrence during the term of your Corinthian Golf Package and will not constitute grounds for refund over the term of your account.',
    'Your Corinthian Account also enable you to purchase additional points for use at Stoke Park or a number of select courses. Booking is conducted via your account and powered via PlayMoreGolf Limited under the XVIII.golf platform.   Stoke Park is not responsible for the sale of these points and any customer services queries relating to use of these points will be handled by XVIII.golf.',
    'You can check in from one hour prior to your tee off time.',
    'Stoke Park reserves the right to close, limit or modify any of its facilities and services without prior notice, for reasons such as maintenance, renovation, safety, weather, events or any other circumstances. ',
    'Stoke Park is not responsible for any loss, damage or injury caused by or arising from the use or misuse of any of its facilities and services, unless caused by its negligence or breach of contract.',
    'You and your party are expected to behave in a courteous and respectful manner towards other guests, staff and property of Stoke Park at all times. You must comply with all rules and regulations of Stoke Park and any applicable laws and regulations. ',
    'You are responsible for any damage or loss caused by you or your party to Stoke Park or any of its property, whether intentional or accidental. You must report any such damage or loss to us as soon as possible and pay for the cost of repair or replacement. ',
    'Stoke Park reserves the right to refuse entry, terminate your Corinthian Golf Package or charge you for any extra costs incurred, if you or your party cause any nuisance, disturbance, damage, loss, injury or breach of these terms and conditions. Stoke Park also reserves the right to report any such incidents to the relevant authorities. ',
    'Pets are not allowed at Stoke Park, except for guide dogs and other assistance dogs. If you wish to bring a guide dog or an assistance dog, please inform us at the time of booking and provide us with the relevant documentation. ',
    'You are responsible for the behaviour and welfare of your guide dog or assistance dog and must ensure that it does not cause any inconvenience, damage or harm to other guests, staff or property of Stoke Park. You must also comply with all applicable laws and regulations regarding the use of guide dogs and assistance dogs.',
    'Children are welcome at Stoke Park, but must be accompanied and supervised by a responsible adult at all times. You are responsible for the safety and behaviour of your children and must ensure that they do not cause any inconvenience, damage or harm to other guests, staff or property of Stoke Park. ',
    'Some of our facilities and services may not be suitable or accessible for children, or may have age restrictions or special requirements. Please check with us before using any of these facilities and services with your children and follow the instructions of our staff at all times. ',
    'Stoke Park is committed to providing a comfortable and accessible environment, regardless of their abilities or needs. ',
    'If you have any specific requirements or requests regarding accessibility, please inform us at the time of booking or as soon as possible and we will do our best to accommodate you. ',
    'Stoke Park respects your privacy and is committed to protecting your personal data. We will only collect, use and share your personal data in accordance with our privacy policy, which can be found here. ',
    'By purchasing a Corinthian Golf Package and making a booking with Stoke Park, you consent to us processing your personal data for the purposes of managing your booking, providing you with our facilities and services, communicating with you, improving our products and services, and complying with our legal obligations. ',
    'You have the right to access, rectify, erase, restrict, object to or withdraw your consent to the processing of your personal data at any time, subject to certain limitations and conditions. You also have the right to lodge a complaint with the relevant data protection authority if you are unhappy with how we handle your personal data. ',
    'If you have any questions or concerns about your personal data, please contact us by email at privacy@stokepark.com or by post at Stoke Park, Park Road, Stoke Poges, Buckinghamshire, SL2 4PG. ',
    'Any disputes relating to your Corinthian account package points should be made in writing to golf@stokepark.com',
  ],
  renew: {
    title: 'Corinthian Collection Renewal Options',
    body: 'Utilise your rounds to play golf on our Harry Colt Championship golf course. Invite friends, colleagues, clients and customers..',
  },
  topup: {
    title: 'Corinthian Collection Top-up Options',
  },
  joinerFee: 100,
});

const foxhills = new Club({
  clubId: 234,
  clubName: 'Foxhills Club & Resort',
  clubNameShort: 'Foxhills',
  mapsLink: 'https://g.page/FoxhillsSurrey?share',
  loginText: 'Learn more about Foxhills',
  address: ['Foxhills', 'Stonehill Road', 'Ottershaw', 'Surrey KT16 0EL'],
  dashboard: {
    message: 'Utilise your points to play golf on our Championship course 7-days a week. Invite friends, colleagues, clients and customers.',
    teeTimesMessage: '',
    spaMessage: '',
  },
  introduction: 'Set in 400 acres of pristine countryside, and just 25 miles from London city centre, Foxhills has been Surrey’s favourite playground for over forty years. From the early days as just a golf club, to today’s full country club and resort (offering golf, leisure, spa, tennis, cycling and more), there really is something for everyone at Foxhills.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['An evolution of our traditional options, our brand new points-based corporate memberships are designed for companies looking for the ultimate flexibility. The naming of both packages, celebrates Foxhills’ rich history as the estate of the mercurial 18th century politician Charles James Fox.',
      'Utilise your points to play golf on either of our two Championship courses or our fabulous par-3 9-hole Manor course. Plus redeem points for our Country Club day passes which can used to experience our healthSpa, gym, swimming pools and tennis courts.',
      'Invite clients, entertain guests or reward members of staff; the choice is yours.'],
  },
  corporatePackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['An evolution of our traditional options, our brand new points-based corporate memberships are designed for companies looking for the ultimate flexibility. The naming of both packages, celebrates Foxhills’ rich history as the estate of the mercurial 18th century politician Charles James Fox.',
      'Utilise your points to play golf on either of our two Championship courses or our fabulous par-3 9-hole Manor course. Plus redeem points for our Country Club day passes which can used to experience our healthSpa, gym, swimming pools and tennis courts.',
      'Invite clients, entertain guests or reward members of staff; the choice is yours.'],
  },
  quotes: ['When you leave, you’ll feel better than when you arrived'],
  explainerImages: [
    {
      link: 'https://www.foxhills.co.uk/dine',
      title: 'Meet',
      desc: 'Experience the most unique meeting venue in Surrey. Just 40 miles from London city centre, Foxhills provides a glamorous retreat for your meeting, ensuring privacy and productivity sit side by side.',
    },
    {
      link: 'https://www.foxhills.co.uk/play',
      title: 'Play',
      desc: 'With unrivalled leisure facilities, the Foxhills Country Club is the ideal place for families and friends to enjoy a relaxed afternoon or an active weekend.',
    },
    {
      link: 'https://www.foxhills.co.uk/spa',
      title: 'Spa',
      desc: 'Welcome to a world of pure relaxation and bliss. Experience the ultimate healthspa break with a visit to Foxhills’ award-winning spa.',
    },
  ],
  renew: {
    title: 'Extend your Foxhills experience',
    body: 'Utilise your points to play golf on our Championship courses 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'More points to play',
  },
  termsAndConditions: {
    link: 'https://www.foxhills.co.uk/legal-terms-and-conditions',
    points: [
      'Payment must be made in advance, by choosing a package on our portal.',
      'Both our Fox and Hayton packages are valid for 12 months only.',
      'Any unused home points will be carried over upon renewal into the following year.',
      'If you choose not to renew into the following year, any unused points will be lost.',
      'Bookings should be made online through your individual account on our portal.',
      'Bookings can be made up to 30 days in advance.',
      'Bookings can be made on any available tee-time, seven days a week.',
      'Cancellations must be made online through your individual account on the portal.',
      'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
      'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
      'Golfers choosing the Fox Package can use points for up to 8 golfers per day.',
      'Golfers choosing the Hayton Package can use the points for up to 12 golfers per day.',
      'Each individual player should check in with the Golf Shop/Reception before play.',
      'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
      'The re-sale of tee-times is not permitted.',
      'Any applicable discounts at Foxhills, as part of the package benefits, are valid only for the account holder or main contact.',
      'Any disputes relating to points balances should be made in writing to golfservices@foxhills.co.uk.',
    ],
  },
});

const theMere = new Club({
  clubId: 541,
  clubName: 'The Mere',
  clubNameShort: 'The Mere',
  disableCheckout: true,
  disablePoints: true,
  telephone: '+44 (0)1889 593564',
  mapsLink: 'https://goo.gl/maps/4yv8fKFXn3p3ATKx7',
  address: ['Hollington Road', 'Rocester', 'Uttoxeter ST14 5HY'],
  loginText: '',
  email: 'golf@themere.co.uk',
  notesTitle: 'Player Names and Handicaps',
  notesRequired: true,
  homeWidgets: [
    { title: 'Tee Times at The Mere', type: 'Golf' },
  ],
  dashboard: {
    message: 'Utilise your rounds to play golf on our internationally-renowned course 7-days a week. Invite friends, colleagues, clients and customers.',
    teeTimesMessage: 'The Mere Tee Times',
    spaMessage: '',
  },
  introduction: 'Surrounded by mature trees, flowering banks and with stunning views over the Mere Lake, the course offers the charm of the Cheshire countryside, coupled with a range of challenging features to test your precision and driving skills! Water, ravines and thick vegetation have been incorporated into the landscape, demanding care and accuracy whilst navigating the course.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: '',
    introduction: [],
  },
  quotes: [
    {
      text: '',
      name: '',
      title: '',
    },
    {
      text: '',
      name: '',
      title: '',
    },
    {
      text: '',
      name: '',
      title: '',
    },
  ],
  explainerImages: [

  ],
  termsAndConditions: [
  ],
  renew: {
    title: 'The Mere Renewal Options',
    body: 'Utilise your rounds to play golf on our internationally-renowned course 7-days a week. Invite friends, colleagues, clients and customers.',
  },
  topup: {
    title: 'The Mere Top-up Options',
  },
});

const jcb = new Club({
  clubId: 537,
  clubName: 'JCB Golf & Country Club',
  clubNameShort: 'JCB Golf',
  disableCheckout: true,
  disablePoints: true,
  telephone: '+44 (0)1889 593564',
  mapsLink: 'https://goo.gl/maps/4yv8fKFXn3p3ATKx7',
  address: ['Hollington Road', 'Rocester', 'Uttoxeter ST14 5HY'],
  loginText: '',
  email: 'golf.reservations@jcb.com',
  notesTitle: 'Player Names and Handicaps',
  notesRequired: true,
  homeWidgets: [
    { title: 'Tee Times at JCB', type: 'Golf' },
  ],
  dashboard: {
    message: 'Utilise your rounds to play golf on our internationally-renowned course 7-days a week. Invite friends, colleagues, clients and customers.',
    teeTimesMessage: 'JCB Tee Times',
    spaMessage: 'Sequoia Spa Availablility',
  },
  introduction: 'The JCB Golf and Country Club reflects the bold, ambitious vision of Lord Bamford, Chairman of the iconic construction and agricultural equipment manufacturer - JCB: nothing less than the creation of a tour-ready contemporary masterpiece in course design.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Clarendon Collection',
    introduction: ['An evolution of the popular Green Book, our new Clarendon Collection is a points-based programme aimed at golfers looking for the ultimate flexibility.', "The naming of Clarendon Collection, and its two packages, celebrates The Grove’s rich history as the family seat of the Earls of Clarendon, a heritage that dates back to the 1600's.", 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.'],
  },
  quotes: [
    {
      text: 'The greens are somewhat undulating but they are really smooth. The golf course is in fantastic shape, the greens are absolutely perfect.',
      name: 'Tiger Woods',
      title: 'Winner of The World Golf Championships 2006',
    },
    {
      text: 'It’s been great to play here over the last few days and the course is in great shape! I’d love to come back and play again!',
      name: 'Alex Noren',
      title: 'Winner of the 2016 British Masters',
    },
    {
      text: 'To everyone at The Grove, thank you for making me look like a great host. Fantastic week!',
      name: 'Luke Donald',
      title: 'Host of the 2016 British Masters',
    },
  ],
  explainerImages: [
    {
      link: 'https://www.thegrove.co.uk/golf/golf-tuition/',
      title: 'Tuition & Practice Facilities',
      desc: 'Whether it’s a warm-up before your round, or a lesson with one of our PGA Professional Team, our grass driving range is the perfect way to improve your golf.',
    },
    {
      link: 'https://www.thegrove.co.uk/golf/plan-your-upcoming-golf-visit/',
      title: 'Rental Items',
      desc: 'Why not hire a golf cart too? With the industry leading state-of-the-art Visage GPS systems installed, our Club Car carts are the perfect way to experience our Championship course.',
    },
    {
      link: 'https://www.thegrove.co.uk/dining/the-stables/',
      title: 'The Stables',
      desc: 'After your round, tuck into The Stables’ menu – homemade, wholesome and wholehearted. Our refreshed restaurant is sporting an elegant new interior. And outside on the terrace, enjoy views across our golf course.',
    },
  ],
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 60 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 48 hours of the tee-time, unless approved by Golf Management.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk ',
  ],
  renew: {
    title: 'JCB Renewal Options',
    body: 'Utilise your points to play golf on our internationally-renowned course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'JCB Top-up Options',
  },
});

const theGroveSpa = new Club({
  clubId: 523,
  clubName: 'Sequoia',
  clubNameShort: 'Sequoia',
  mapsLink: 'https://goo.gl/maps/NK3TPXWkTeiTt1jp9',
  address: ['The Grove', "Chandler's Cross", 'Hertfordshire WD3 4TG'],
  loginText: '',
  email: 'spamembership@thegrove.co.uk',
  telephone: '01923 295926',
  homeWidgets: [
    { title: 'Treatments', type: 'Spa' },
    { title: 'Golf', type: 'Golf' },
    { title: 'Dining', type: 'Restaurant' },
    // { title: 'Hotel', type: 'Hotel' },
    { title: 'Personal Training & Guest Passes', type: 'Personal Training & Guest Passes' },
  ],
  spa: {
    title: 'Treatments',
    description: 'With its black mosaic-tiled pool, heat experience rooms and specialist treatment options, our award winning Sequoia spa is an experience like no other. Change into one of our luxury robes, leave your stresses behind and sink into a world of relaxation and recuperation.',
  },
  restaurant: {
    title: 'Dining at The Grove',
    description: 'Tantalise your taste buds at The Glasshouse, feast on sustainably-sourced dishes at The Stables or enjoy an after dinner tipple in our newly refurbished Lounges.',
  },
  dashboard: {
    message: 'Utilise your points to play golf on our Championship course and indulge in our award-winning and newly refurbished Sequoia spa, with an experience like no other.',
    teeTimesMessage: 'The Grove Tee Times',
    spaMessage: 'Sequoia Spa',
  },
  introduction: 'Set in 300 acres of stunning Hertfordshire countryside, The Grove is the ultimate five-star retreat. Lovingly restored, the former home of the Earls of Clarendon is situated just 18 miles from London.  With it’s immaculate greens and stunning views, the 7,152 yard championship golf courses is one of the finest in England.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Clarendon Collection',
    introduction: ['An evolution of the popular Green Book, our new Clarendon Collection is a points-based programme aimed at golfers looking for the ultimate flexibility.', "The naming of Clarendon Collection, and its two packages, celebrates The Grove’s rich history as the family seat of the Earls of Clarendon, a heritage that dates back to the 1600's.", 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.'],
  },
  quotes: [
    {
      text: 'The greens are somewhat undulating but they are really smooth. The golf course is in fantastic shape, the greens are absolutely perfect.',
      name: 'Tiger Woods',
      title: 'Winner of The World Golf Championships 2006',
    },
    {
      text: 'It’s been great to play here over the last few days and the course is in great shape! I’d love to come back and play again!',
      name: 'Alex Noren',
      title: 'Winner of the 2016 British Masters',
    },
    {
      text: 'To everyone at The Grove, thank you for making me look like a great host. Fantastic week!',
      name: 'Luke Donald',
      title: 'Host of the 2016 British Masters',
    },
  ],
  explainerImages: [
    {
      link: 'https://www.thegrove.co.uk/golf/golf-tuition/',
      title: 'Tuition & Practice Facilities',
      desc: 'Whether it’s a warm-up before your round, or a lesson with one of our PGA Professional Team, our grass driving range is the perfect way to improve your golf.',
    },
    {
      link: 'https://www.thegrove.co.uk/golf/plan-your-upcoming-golf-visit/',
      title: 'Rental Items',
      desc: 'Why not hire a golf cart too? With the industry leading state-of-the-art Visage GPS systems installed, our Club Car carts are the perfect way to experience our Championship course.',
    },
    {
      link: 'https://www.thegrove.co.uk/dining/the-stables/',
      title: 'The Stables',
      desc: 'After your round, tuck into The Stables’ menu – homemade, wholesome and wholehearted. Our refreshed restaurant is sporting an elegant new interior. And outside on the terrace, enjoy views across our golf course.',
    },
  ],
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 60 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 48 hours of the tee-time, unless approved by Golf Management.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk ',
  ],
  renew: {
    title: 'Sequoia Spa… Renewal Options',
    body: 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'Sequoia Spa… Top-up Options',
  },
});

const theGrove = new Club({
  clubId: 449,
  clubName: 'The Grove',
  clubNameShort: 'The Grove',
  disableLanding: false,
  telephone: '01923 294266',
  mapsLink: 'https://goo.gl/maps/NK3TPXWkTeiTt1jp9',
  address: ['The Grove', "Chandler's Cross", 'Hertfordshire WD3 4TG'],
  loginText: 'Learn more about the Clarendon Collection',
  email: 'tee.time@thegrove.co.uk',
  notesTitle: 'Notes',
  homeWidgets: [
    { title: 'Tee Times at The Grove', type: 'Golf' },
  ],
  dashboard: {
    message: 'Utilise your points to play golf on our Championship course 7-days a week. Invite friends, colleagues, clients and customers.',
    teeTimesMessage: 'The Grove Tee Times',
    spaMessage: 'Sequoia Spa Availablility',
  },
  introduction: 'Set in 300 acres of stunning Hertfordshire countryside, The Grove is the ultimate five-star retreat. Lovingly restored, the former home of the Earls of Clarendon is situated just 18 miles from London.  With it’s immaculate greens and stunning views, the 7,152 yard championship golf courses is one of the finest in England.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Clarendon Collection',
    introduction: ['An evolution of the popular Green Book, our new Clarendon Collection is a points-based programme aimed at golfers looking for the ultimate flexibility.', "The naming of Clarendon Collection, and its two packages, celebrates The Grove’s rich history as the family seat of the Earls of Clarendon, a heritage that dates back to the 1600's.", 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.'],
  },
  quotes: [
    {
      text: 'The greens are somewhat undulating but they are really smooth. The golf course is in fantastic shape, the greens are absolutely perfect.',
      name: 'Tiger Woods',
      title: 'Winner of The World Golf Championships 2006',
    },
    {
      text: 'It’s been great to play here over the last few days and the course is in great shape! I’d love to come back and play again!',
      name: 'Alex Noren',
      title: 'Winner of the 2016 British Masters',
    },
    {
      text: 'To everyone at The Grove, thank you for making me look like a great host. Fantastic week!',
      name: 'Luke Donald',
      title: 'Host of the 2016 British Masters',
    },
  ],
  explainerImages: [
    {
      link: 'https://www.thegrove.co.uk/golf/golf-tuition/',
      title: 'Tuition & Practice Facilities',
      desc: 'Whether it’s a warm-up before your round, or a lesson with one of our PGA Professional Team, our grass driving range is the perfect way to improve your golf.',
    },
    {
      link: 'https://www.thegrove.co.uk/golf/plan-your-upcoming-golf-visit/',
      title: 'Rental Items',
      desc: 'Why not hire a golf cart too? With the industry leading state-of-the-art Visage GPS systems installed, our Club Car carts are the perfect way to experience our Championship course.',
    },
    {
      link: 'https://www.thegrove.co.uk/dining/the-stables/',
      title: 'The Stables',
      desc: 'After your round, tuck into The Stables’ menu – homemade, wholesome and wholehearted. Our refreshed restaurant is sporting an elegant new interior. And outside on the terrace, enjoy views across our golf course.',
    },
  ],
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 60 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 48 hours of the tee-time, unless approved by Golf Management.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk ',
  ],
  renew: {
    title: 'Clarendon Collection… Renewal Options',
    body: 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'Clarendon Collection… Top-up Options',
  },
});

const theGroveTest = new Club({
  clubId: 238,
  clubName: 'The Grove',
  clubNameShort: 'The Grove',
  disableLanding: false,
  email: 'tee.time@thegrove.co.uk',
  mapsLink: 'https://goo.gl/maps/NK3TPXWkTeiTt1jp9',
  address: ['The Grove', "Chandler's Cross", 'Hertfordshire WD3 4TG'],
  loginText: 'Learn more about the Clarendon Collection',
  dashboard: {
    message: 'Utilise your points to play golf on our Championship course 7-days a week. Invite friends, colleagues, clients and customers.',
    teeTimesMessage: 'The Grove Tee Times',
    spaMessage: 'Sequoia Spa Availablility',
  },
  introduction: 'Set in 300 acres of stunning Hertfordshire countryside, The Grove is the ultimate five-star retreat. Lovingly restored, the former home of the Earls of Clarendon is situated just 18 miles from London.  With it’s immaculate greens and stunning views, the 7,152 yard championship golf courses is one of the finest in England.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Clarendon Collection',
    introduction: ['An evolution of the popular Green Book, our new Clarendon Collection is a points-based programme aimed at golfers looking for the ultimate flexibility.', "The naming of Clarendon Collection, and its two packages, celebrates The Grove’s rich history as the family seat of the Earls of Clarendon, a heritage that dates back to the 1600's.", 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.'],
  },
  quotes: [
    {
      text: 'The greens are somewhat undulating but they are really smooth. The golf course is in fantastic shape, the greens are absolutely perfect.',
      name: 'Tiger Woods',
      title: 'Winner of The World Golf Championships 2006',
    },
    {
      text: 'It’s been great to play here over the last few days and the course is in great shape! I’d love to come back and play again!',
      name: 'Alex Noren',
      title: 'Winner of the 2016 British Masters',
    },
    {
      text: 'To everyone at The Grove, thank you for making me look like a great host. Fantastic week!',
      name: 'Luke Donald',
      title: 'Host of the 2016 British Masters',
    },
  ],
  explainerImages: [
    {
      link: 'https://www.thegrove.co.uk/golf/golf-tuition/',
      title: 'Tuition & Practice Facilities',
      desc: 'Whether it’s a warm-up before your round, or a lesson with one of our PGA Professional Team, our grass driving range is the perfect way to improve your golf.',
    },
    {
      link: 'https://www.thegrove.co.uk/golf/plan-your-upcoming-golf-visit/',
      title: 'Rental Items',
      desc: 'Why not hire a golf cart too? With the industry leading state-of-the-art Visage GPS systems installed, our Club Car carts are the perfect way to experience our Championship course.',
    },
    {
      link: 'https://www.thegrove.co.uk/dining/the-stables/',
      title: 'The Stables',
      desc: 'After your round, tuck into The Stables’ menu – homemade, wholesome and wholehearted. Our refreshed restaurant is sporting an elegant new interior. And outside on the terrace, enjoy views across our golf course.',
    },
  ],
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 30 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
    'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any applicable discounts at The Grove, as part of the package benefits, are valid only for the account holder or main contact.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk.',
    'The Clarendon Collection cannot be used in conjunction with any other offers.',
  ],
  renew: {
    title: 'Clarendon Collection… Renewal Options',
    body: 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'Clarendon Collection… Top-up Options',
  },
});

const playMoreGolfResort = new Club({
  clubId: 151,
  clubName: 'PlayMoreGolf Resort',
  packages: [
    {
      id: 0,
      name: 'Hyde',
      price: '£3,200',
      homePoints: '800',
      playersPerDay: 8,
      shortDescription: '£3,200 for 800 points, plus receive the following benefits',
      longDescription: 'Play up to 50 rounds of golf and receive selected benefits. Manage your account and book golf online.',
      benefits:
        [
          'Play up to 50 rounds of golf',
          'Manage your account and book golf online',
          'Use your points for up to 8 people per day',
          'Includes ability to maintain your handicap',
          '10% off all food and beverage purchased in The Stables restaurant and The Halfway House',
          '10% off services including tuition, retail and rental items',
          '18-hole playing lesson with a member of the PGA golf professional team',
          '55-minute Trackman lesson with a member of the PGA golf professional team',
          '5 driving range passes',
          'Preferential group golf rate',
        ],
    },
    {
      id: 1,
      name: 'Villiers',
      price: '£6,000',
      homePoints: '1500',
      playersPerDay: 12,
      shortDescription: '£6,000 for 1500 points, plus receive the following benefits',
      longDescription: 'Play up to 100 rounds of golf and receive selected benefits. Manage your account and book golf online.',
      benefits:
        ['Play up to 100 rounds of golf',
          'Manage your account and book golf online',
          'Use your points for up to 12 people per day',
          'Includes ability to maintain your handicap',
          '15% off all food and beverage purchased in The Stables restaurant and The Halfway House',
          '15% off services including tuition, retail and rental items',
          '18-hole playing lesson with a member of the PGA golf professional team',
          '120-minute Trackman lesson with a member of the PGA golf professional team',
          '5 driving range passes',
          'Preferential group golf rate'],
    },
  ],
});

const eighteenClubDemo = new Club({
  clubId: 259,
  clubName: 'EighteenClub',
  clubNameShort: 'XVIII',
  disableLanding: false,
  mapsLink: 'https://goo.gl/maps/YYMjiV97qSFubLm29',
  loginText: 'Learn more about EighteenClub',
  address: ['XVIII Golf', 'Demo Road', 'Demoton', 'XM4 5AS'],
  dashboardMessage: 'Utilise your points to play golf on our courses 7-days a week. Invite friends, colleagues, clients and customers.',
  introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Premium Membership',
    introduction: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
      'Donec semper consequat massa, non consequat eros consequat nec. Nunc id dapibus nisi. Donec pretium massa vel lectus tincidunt tempus. Nulla facilisi.',
      'Nulla venenatis varius massa vitae bibendum.'],
  },
  corporatePackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
      'Donec semper consequat massa, non consequat eros consequat nec. Nunc id dapibus nisi. Donec pretium massa vel lectus tincidunt tempus. Nulla facilisi.',
      'Nulla venenatis varius massa vitae bibendum.'],
  },
  quotes: ['Curabitur congue vel ante eu malesuada, non consequat eros consequat nec.'],
  explainerImages: [
    {
      link: 'https://www.xviii.golf',
      title: 'Meet',
      desc: 'Vivamus libero augue, congue ac tincidunt eget, faucibus vel neque. Vestibulum a tincidunt eros. Donec nec est tortor. Aliquam erat volutpat. Donec laoreet dui erat, sit amet ultricies ex fermentum at.',
    },
    {
      link: 'https://www.xviii.golf',
      title: 'Play',
      desc: 'Nunc condimentum justo vitae sem tempus, sollicitudin elementum metus fermentum. Duis at laoreet sem, vel posuere lacus.',
    },
    {
      link: 'https://www.xviii.golf',
      title: 'Spa',
      desc: 'Nunc et risus hendrerit, rutrum magna at, dignissim eros. In vestibulum ex euismod massa ornare, et porta felis bibendum.',
    },
  ],
  renew: {
    title: 'Extend your XVIII experience',
    body: 'Curabitur congue vel ante eu malesuada. Praesent viverra sollicitudin metus, et congue felis commodo ut. Vivamus ullamcorper sollicitudin risus eu pulvinar. Vivamus sollicitudin turpis nec justo imperdiet varius.',
  },
  topup: {
    title: 'More points to play',
  },
  termsAndConditions: {
    link: 'https://www.xviii.golf',
    points: [
      'Payment must be made in advance, by choosing a package on our portal.',
      'Both our Fox and Hayton packages are valid for 12 months only.',
      'Any unused home points will be carried over upon renewal into the following year.',
      'If you choose not to renew into the following year, any unused points will be lost.',
      'Bookings should be made online through your individual account on our portal.',
      'Bookings can be made up to 30 days in advance.',
      'Bookings can be made on any available tee-time, seven days a week.',
      'Cancellations must be made online through your individual account on the portal.',
      'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
      'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
      'Each individual player should check in with the Golf Shop/Reception before play.',
      'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
      'The re-sale of tee-times is not permitted.',
      'Any applicable discounts at XVIII, as part of the package benefits, are valid only for the account holder or main contact.',
      'Any disputes relating to points balances should be made in writing to golfservices@xviii.golf.',
    ],
  },
});

const eighteenClubDemo2 = new Club({
  clubId: 260,
  clubName: 'EighteenClub',
  clubNameShort: 'XVIII',
  disableLanding: false,
  mapsLink: 'https://goo.gl/maps/YYMjiV97qSFubLm29',
  loginText: 'Learn more about EighteenClub',
  address: ['XVIII Golf', 'Demo Road', 'Demoton', 'XM4 5AS'],
  dashboardMessage: 'Utilise your points to play golf on our courses 7-days a week. Invite friends, colleagues, clients and customers.',
  introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Premium Membership',
    introduction: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
      'Donec semper consequat massa, non consequat eros consequat nec. Nunc id dapibus nisi. Donec pretium massa vel lectus tincidunt tempus. Nulla facilisi.',
      'Nulla venenatis varius massa vitae bibendum.'],
  },
  corporatePackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
      'Donec semper consequat massa, non consequat eros consequat nec. Nunc id dapibus nisi. Donec pretium massa vel lectus tincidunt tempus. Nulla facilisi.',
      'Nulla venenatis varius massa vitae bibendum.'],
  },
  quotes: ['Curabitur congue vel ante eu malesuada, non consequat eros consequat nec.'],
  explainerImages: [
    {
      link: 'https://www.xviii.golf',
      title: 'Meet',
      desc: 'Vivamus libero augue, congue ac tincidunt eget, faucibus vel neque. Vestibulum a tincidunt eros. Donec nec est tortor. Aliquam erat volutpat. Donec laoreet dui erat, sit amet ultricies ex fermentum at.',
    },
    {
      link: 'https://www.xviii.golf',
      title: 'Play',
      desc: 'Nunc condimentum justo vitae sem tempus, sollicitudin elementum metus fermentum. Duis at laoreet sem, vel posuere lacus.',
    },
    {
      link: 'https://www.xviii.golf',
      title: 'Spa',
      desc: 'Nunc et risus hendrerit, rutrum magna at, dignissim eros. In vestibulum ex euismod massa ornare, et porta felis bibendum.',
    },
  ],
  renew: {
    title: 'Extend your XVIII experience',
    body: 'Curabitur congue vel ante eu malesuada. Praesent viverra sollicitudin metus, et congue felis commodo ut. Vivamus ullamcorper sollicitudin risus eu pulvinar. Vivamus sollicitudin turpis nec justo imperdiet varius.',
  },
  topup: {
    title: 'More points to play',
  },
  termsAndConditions: {
    link: 'https://www.xviii.golf',
    points: [
      'Payment must be made in advance, by choosing a package on our portal.',
      'Both our Fox and Hayton packages are valid for 12 months only.',
      'Any unused home points will be carried over upon renewal into the following year.',
      'If you choose not to renew into the following year, any unused points will be lost.',
      'Bookings should be made online through your individual account on our portal.',
      'Bookings can be made up to 30 days in advance.',
      'Bookings can be made on any available tee-time, seven days a week.',
      'Cancellations must be made online through your individual account on the portal.',
      'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
      'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
      'Each individual player should check in with the Golf Shop/Reception before play.',
      'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
      'The re-sale of tee-times is not permitted.',
      'Any applicable discounts at XVIII, as part of the package benefits, are valid only for the account holder or main contact.',
      'Any disputes relating to points balances should be made in writing to golfservices@xviii.golf.',
    ],
  },
});

const clubList = [
  defaultClub,
  theGrove,
  theGroveTest,
  foxhills,
  playMoreGolfResort,
  eighteenClubDemo,
  theGroveSpa,
  jcb,
  theMere,
  eighteenClubDemo2,
  stokePark,
];

export { clubList };
