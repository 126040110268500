class Club {
  constructor(params) {
    this.clubId = params.clubId;
    this.clubName = params.clubName;
    this.clubNameShort = params.clubNameShort;
    this.disableCheckout = params.disableCheckout;
    this.disablePoints = params.disablePoints;
    this.disableLanding = params.disableLanding === undefined ? true : params.disableLanding;
    this.spa = params.spa;
    this.restaurant = params.restaurant;
    this.homeWidgets = params.homeWidgets;
    this.mapsLink = params.mapsLink;
    this.email = params.email;
    this.notesTitle = params.notesTitle;
    this.notesRequired = params.notesRequired;
    this.telephone = params.telephone;
    this.loginText = params.loginText;
    this.registerText = params.registerText;
    this.dashboard = params.dashboard;
    this.address = params.address;
    this.introduction = params.introduction;
    this.standardPackage = params.standardPackage;
    this.corporatePackage = params.corporatePackage;
    this.quotes = params.quotes;
    this.bookingLogo = params.bookingLogo;
    this.explainerImages = params.explainerImages;
    this.renew = params.renew;
    this.topup = params.topup;
    this.termsAndConditions = params.termsAndConditions;
    this.website = params.website;
    this.joinerFee = params.joinerFee;
  }
}

export { Club };
