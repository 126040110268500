
<template>
  <div
    class="h-full min-h-full w-full min-w-full"
    style="background-color: #f3f3f3;"
    :class="theme.cssTheme"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="showLoadingSpinner"
        class="flex h-full min-h-full w-full min-w-full items-center justify-center"
      >
        <div class="loader" />
      </div>
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="showContent"
        :class="environment === 'cordova'?'noSelect':''"
      >
        <site-header
          v-if="showLayout"
          @sidebarClick="sidebarClick"
        />
        <div class="flex">
          <div
            class="h-0 transition-all duration-300 xxxs:h-0 md:h-full"
            :style="sideBarOpen && !$isMobile.value ? 'width: 22rem' : 'width: 0px'"
          />
          <router-view
            class="h-full min-h-full w-full"
            :style="showLayout?'margin-top: 75px;':''"
          />
        </div>
        <mobileMenuBar
          v-if="false"
          class="fixed bottom-0 left-0 w-full min-w-full"
        />
      </div>
    </transition>

    <!-- <div
      v-if="environment !== 'cordova' && !cookieClose"
      :class="cookieClose === null && !showLoadingSpinner && theme.clubId !== 0?'opacity-100':'opacity-0'"
      class="transition-all duration-1000 fixed bottom-0 left-0 w-full min-w-full"
    >
      <cookieBanner />
    </div> -->

    <v-offline @detected-condition="handleConnectivityChange" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VOffline from 'v-offline';
import siteHeader from './shared/components/layout/siteHeader/siteHeader.vue';
import mobileMenuBar from './shared/components/layout/mobileMenuBar/mobileMenuBar.vue';
import router from './app-routes';
import '../assets/site.scss';
import '../assets/clubBranding.scss';
import services from '@/app/shared/services';

import { nodeEnv } from '@/environment/environment';

export default {
  name: 'App',

  components: {
    siteHeader,
    mobileMenuBar,
    VOffline,
  },

  data() {
    return {
      loading: true,
      environment: undefined,
      online: true,
      loadedFonts: [],
      cookieClose: undefined,

      sideBarOpen: false,
    };
  },

  computed: {
    theme() {
      return this.$store.getters.getCurrentTheme;
    },

    memberSummary() {
      return this.$store.getters.getMemberSummary;
    },

    showLayout() {
      return this.$store.state.theme.showLayout;
    },

    hideGlobalLoader() {
      return this.$store.state.theme.hideGlobalLoader;
    },

    hideHomeClub() {
      return this.$store.state.theme.hideHomeClub;
    },

    ...mapState({
      username: state => state.user.username,
      IsExpired: state => state.user.IsExpired,
      isAuthenticated: state => state.user.isAuthenticated,
      homeClubId: state => state.user.homeClubId,
      homeClubName: state => state.user.homeClubName,
      completedFirstPayment: state => state.user.completedFirstPayment,
    }),

    showLoadingSpinner() {
      if (this.username === '' && !this.hideGlobalLoader && this.online) {
        return true;
      }
      return false;
    },

    showContent() {
      if (this.username !== '' || this.hideGlobalLoader || !this.online) {
        return true;
      }
      return false;
    },

  },

  watch: {
    online() {
      if (nodeEnv === 'cordova') {
        if (this.online) {
          if (router.currentRoute.name === 'offline') {
            router.push('/');
          }
        } else {
          router.push('/Error/Offline');
        }
      }
    },

    theme() {
      this.loadFonts();
    },

    $route() {
      this.checkPermissions();
    },
  },

  created() {
    this.environment = nodeEnv;
    this.injectAssets();
    this.checkPermissions();
  },

  mounted() {
    services.account.cookieAuthenticated().then(() => {
      this.$store.dispatch('populateMemberSummary');
      if (this.isAuthenticated) {
        this.$store.dispatch('refreshBasket');
        // i think this will be covered by the member summary
        // this.$store.dispatch('refreshPoints', false);
        this.loadFonts();
      } else {
        // this.loading = false;
      }
    });

    window.onresize = () => {
      this.$isMobile.value = process.env.VUE_APP_ENV === 'cordova' || window.innerWidth < 924;
    };
  },

  methods: {
    sidebarClick(value) {
      this.sideBarOpen = value;
    },

    loadFonts() {
      const { fonts } = this.theme;

      if (fonts !== undefined) {
        for (let i = 0; i < fonts.length; i += 1) {
          if (!this.loadedFonts.includes(fonts[i].family)) {
            this.loadedFonts.push(fonts[i].family);

            const style = document.createElement('style');
            style.appendChild(document.createTextNode(`\
            @font-face {\
                font-family: ${fonts[i].family};\
                src: url('${fonts[i].src}') format('${fonts[i].format}');\
            }\
            `));

            document.head.appendChild(style);
          }
        }
      }
    },

    handleConnectivityChange(status) {
      this.online = status;
    },

    injectAssets() {
      // Horrible
      const baseUrl = process.env.VUE_APP_BASE_URL;

      const indexJs = document.createElement('script');
      indexJs.setAttribute('type', 'text/javascript');
      indexJs.setAttribute('src', `${baseUrl}js/index.js`);
      document.head.appendChild(indexJs);

      const siteJs = document.createElement('script');
      siteJs.setAttribute('type', 'text/javascript');
      siteJs.setAttribute('src', `${baseUrl}js/site.js`);
      document.head.appendChild(siteJs);

      const siteCss = document.createElement('link');
      siteCss.setAttribute('rel', 'stylesheet');
      siteCss.setAttribute('type', 'text/css');
      siteCss.setAttribute('href', `${baseUrl}css/site.css`);
      document.head.appendChild(siteCss);

      if (this.environment === 'cordova') {
        const cordovaJs = document.createElement('script');
        cordovaJs.setAttribute('type', 'text/javascript');
        cordovaJs.setAttribute('src', 'cordova.js');
        document.head.appendChild(cordovaJs);
      }
    },

    checkPermissions() {
      if (router.currentRoute.meta.requiresAuth && this.username !== '') {
        if (!this.completedFirstPayment) {
          if (router.currentRoute.name !== 'summary' && router.currentRoute.name !== 'packages') {
            router.push('/Checkout');
          }
        }
      }
    },
  },

};
</script>

<style src="../assets/site.css"></style>
