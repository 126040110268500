<template>
  <div class="fixed inset-x-0 bottom-0 z-30 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      v-if="loading"
      class="fixed left-0 top-0 z-20 flex h-screen min-h-screen w-screen min-w-full items-center justify-center"
    >
      <loadingSpinner class="text-centers mx-auto" />
    </div>

    <div
      v-if="!loading"
      class="z-20 transform overflow-hidden bg-white shadow-xl transition-all sm:w-full sm:max-w-lg"
    >
      <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg
              class="h-6 w-6 text-red-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 class="brand-heading text-lg font-medium leading-6 text-gray-900">
              Cancel {{ currentTheme.buddiesText }} request
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-600">
                Are you sure you want to cancel your {{ currentTheme.buddiesText }} request to {{ buddy.name }}?
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <span
          class="flex w-full shadow-sm sm:ml-3 sm:w-auto"
        >
          <button
            type="button"
            class="brand-button focus:shadow-outline-red inline-flex w-full justify-center border border-transparent bg-red-600 px-3 py-2 text-lg font-medium leading-6 text-white
            shadow-sm transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none sm:text-base sm:leading-5"
            @click="cancelBuddyRequest()"
          >
            Cancel
          </button>
        </span>
        <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
          <button
            type="button"
            class="brand-button-secondary focus:shadow-outline inline-flex w-full justify-center px-4 py-2 text-lg font-medium leading-6
             text-brand-500 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-base sm:leading-5"
            @click="$parent.openCancelBuddy = false"
          >
            Back
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/app/shared/services';
import LoadingSpinner from '../base/loadingSpinner.vue';
import { mapGetters } from 'vuex';

export default {

  components: {
    LoadingSpinner,
  },

  props: {
    buddy: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  methods: {
    cancelBuddyRequest() {
      this.loading = true;
      services.buddy.cancelBuddyRequest(this.buddy.target).then(() => {
        this.$parent.openCancelBuddy = false;
        this.$parent.$parent.getPendingBuddies();
      });
    },
  },

};


</script>
