const spaRoutes = [
  {
    path: '/spa/:clubId/:courseId',
    name: 'spaSearch',
    component: () => import('./spa.vue'),
    meta: {
      title: 'Sequoia Spa',
    },
  },
];

export default spaRoutes;
