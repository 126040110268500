const checkoutRoutes = [
  {
    path: '/Checkout',
    name: 'summary',
    component: () => import('./summary/summary.vue'),
    meta: {
      title: 'Checkout',
      requiresAuth: true,
      squeezePage: true,
    },
  },
  {
    path: '/PaymentProcessing',
    name: 'paymentProcessing',
    component: () => import('./paymentProcessing/paymentProcessing.vue'),
    meta: {
      title: 'Payment Processing...',
      requiresAuth: false,
      squeezePage: true,
    },
  },
  {
    path: '/Welcome',
    name: 'welcome',
    component: () => import('./welcome/welcome.vue'),
    meta: {
      title: 'Payment Successful',
      requiresAuth: false,
    },
  },
  {
    path: '/TopupComplete',
    name: 'topupComplete',
    component: () => import('./topup/topUpComplete.vue'),
    meta: {
      title: 'Top-up Successful',
    },
  },
];

export default checkoutRoutes;
