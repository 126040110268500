import buddyManagement from './buddyManagement/buddyManagement.vue';

const buddyRoutes = [
  {
    path: '/Buddies',
    component: buddyManagement,
    meta: {
      title: 'My Buddies',
    },
  },
  {
    path: '/Colleagues',
    component: buddyManagement,
    meta: {
      title: 'My Colleagues',
    },
  },
  {
    path: '/Friends',
    component: buddyManagement,
    meta: {
      title: 'My Friends',
    },
  },
];

export default buddyRoutes;
