import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { themeStore } from './themeStore';
// eslint-disable-next-line import/no-cycle
import { userStore } from './userStore';
import { clubStore } from './clubStore';
import { basketStore } from './basketStore';

const persistenceBit = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    user: {
      isAuthenticated: state.user.isAuthenticated,
      homeClubId: state.user.homeClubId,
      homeClubName: state.user.homeClubName,
    },
  }),
});

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user: userStore,
    theme: themeStore,
    basket: basketStore,
    club: clubStore,
  },
  plugins: [persistenceBit.plugin],
});

export { store };
