<template>
  <div
    id="primaryButton"
    class="brand-button transition-500 transition-ease-in-out relative select-none appearance-none overflow-hidden text-center align-baseline leading-none transition-all"
    :class="getClasses()"
    @click="emitClick"
  >
    <div
      v-if="!loading"
    >
      {{ text }}
    </div>
    <LoadingSpinner
      v-else
      :white="true"
      class=""
    />
  </div>
</template>

<script>
import LoadingSpinner from '../loadingSpinner.vue';

export default {

  components: {
    LoadingSpinner,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    textSize: {
      type: String,
      default: 'text-lg ',
    },
    padding: {
      type: String,
      default: 'py-3 px-2 ',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    whiteLoadingSpinner: {
      type: Boolean,
      default: false,
    },
  },

  computed: {

  },

  methods: {
    emitClick() {
      if (!this.loading && !this.disabled) {
        this.$emit('click');
      }
    },

    getClasses() {
      let classes = `${this.textSize} ${this.padding} `;

      if (this.fullWidth) {
        classes += 'w-full ';
      }

      if (this.loading) {
        classes += 'cursor-wait ';
      } else if (!this.disabled) {
        classes += 'cursor-pointer ';
      }

      if (this.disabled) {
        classes += 'bg-gray-200 hover:bg-gray-200 text-gray-600 hover:text-gray-600 cursor-default ';
      }

      return classes;
    },
  },
};
</script>
