<template>
  <div class="fixed inset-x-0 bottom-0 z-30 px-4 pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="z-10 transform overflow-hidden bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="mb-4">
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="users"
            class="h-6 w-6 text-green-600"
            role="img"
            viewBox="0 0 640 512"
          ><g class="fa-group"><path
            class="fa-secondary"
            fill="currentColor"
            d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
            opacity="0.4"
          /><path
            class="fa-primary"
            fill="currentColor"
            d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
          /></g></svg>
        </div>

        <div class="brand-body mt-2 text-center">
          <h3 class="brand-heading text-lg leading-6 text-gray-900">
            What is a share code?
          </h3>
          <div class="mt-4">
            <p class="text-sm leading-5 text-gray-800">
              A share code helps your {{ currentTheme.buddiesText }} find you
            </p>
          </div>

          <div class="mt-4 text-left">
            <p class="text-sm text-gray-700">
              You can give this sharing code to your {{ currentTheme.buddiesTextPlural }} to make it easy for them to send a request to you.
            </p>

            <p class="mt-2 text-sm text-gray-700">
              Ask them to use this code in the "Add {{ currentTheme.buddiesText }}" section on their {{ currentTheme.buddiesText }} management page.
            </p>

            <p class="mt-2 text-sm text-gray-700">
              Even with the Share Code, a {{ currentTheme.buddiesText }} request needs to be confirmed by you before you'll become {{ currentTheme.buddiesTextPlural }}.
            </p>

            <p class="mb-4 mt-2 text-sm leading-5 text-red-700">
              For security and privacy reasons we recommend you use your unique Share Code instead of sharing your email address.
            </p>
          </div>
        </div>
      </div>


      <div class="mt-4">
        <div class="inline-block w-full">
          <div
            class="brand-button cursor-pointer p-1 text-center text-white shadow transition-all duration-500"
            @click="$parent.openShareCodeInfo = false;"
          >
            Close
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },
};
</script>
