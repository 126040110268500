import services from '@/app/shared/services';

const basketStore = {
  state: {
    basket: undefined,
    refreshingBasket: false,
    openBasketMenu: false,
  },

  getters: {
    getBasket: state => state.basket,
  },

  mutations: {
    pushBasket(state, basket) {
      state.basket = basket;
    },

    switchBasketMenuState(state) {
      state.openBasketMenu = !state.openBasketMenu;
    },

    setBasketMenuState(state, value) {
      state.openBasketMenu = value;
    },

    setRefreshingBasket(state, value) {
      state.refreshingBasket = value;
    },
  },

  actions: {
    setBasketBundle(context, bundle) {
      return new Promise((resolve) => {
        context.commit('setRefreshingBasket', true);
        services.checkout.emptyBasket().then(() => {
          services.checkout.setBasket(bundle.BundleId, bundle.PromoCode, bundle.NewClubId, null).then(() => {
            services.checkout.getBasket().then((basket) => {
              context.commit('pushBasket', basket);
              context.commit('setRefreshingBasket', false);

              resolve();
            });
          });
        });
      });
    },

    refreshBasket(context) {
      return new Promise((resolve) => {
        context.commit('setRefreshingBasket', true);
        services.checkout.getBasket().then((basket) => {
          context.commit('pushBasket', basket);
          context.commit('setRefreshingBasket', false);
          resolve();
        });
      });
    },

    emptyBasket(context) {
      return new Promise((resolve) => {
        context.commit('setRefreshingBasket', true);
        services.checkout.emptyBasket().then(() => {
          services.checkout.getBasket().then((basket) => {
            context.commit('pushBasket', basket);
            context.commit('setRefreshingBasket', false);

            resolve();
          });
        });
      });
    },

    async setBasketMenuState({ commit }, value) {
      commit('setBasketMenuState', value);
    },

    async switchBasketMenuState({ commit }) {
      commit('switchBasketMenuState');
    },
  },
};

export { basketStore };
