<template>
  <div
    class="packageName brand-heading inline-block border bg-white p-5 text-center text-brand-500 shadow-lg"
    style="max-width: 430px;"
  >
    <div class="border border-gray-200 p-4 px-6">
      <div
        v-if="clubPackage !== undefined"
        class="packageName brand-heading text-3xl text-brand-500"
      >
        <span class="text-4xl">{{ clubPackage.Name.charAt(0) }}</span>{{ clubPackage.Name.substring(1) }} Package
      </div>

      <div class="mb-2 mt-4 flex items-center justify-center text-center">
        <div
          v-if="clubPackage.Bundles[0].BaselineCost !== clubPackage.Bundles[0].Cost"
          class="packagePrice mb-2 mr-2 text-lg text-black line-through opacity-90"
        >
          {{ clubPackage.Bundles[0].Currency.CurrencySymbol }}{{ clubPackage.Bundles[0].BaselineCost }}
        </div>
        <div class="packagePrice text-4xl text-gray-700">
          <span v-if="club.joinerFee !== undefined">{{ clubPackage.Bundles[0].Currency.CurrencySymbol }}{{ (clubPackage.Bundles[0].Cost - club.joinerFee).toLocaleString() }}</span>
          <span v-else>{{ clubPackage.Bundles[0].Currency.CurrencySymbol }}{{ (clubPackage.Bundles[0].Cost).toLocaleString() }}</span>
        </div>
        <div class="ml-1 mt-2 text-base">
          / year
        </div>
      </div>

      <div
        v-if="!completedFirstPayment && club.joinerFee !== undefined"
        class="my-2"
      >
        £{{ club.joinerFee }} joiner fee required
      </div>

      <div class="packageBenefits brand-body my-4 text-lg">
        {{ clubPackage.Description }}
      </div>

      <div v-if="club.joinerFee === undefined || !completedFirstPayment">
        <div
          v-if="!clubPackage.IsFull || completedFirstPayment || showFullPackages"
          class="inline-block"
        >
          <div
            v-if="isInBasket"
            class="brand-button transition-200 transition-ease-in-out mb-2 mt-4 inline-block cursor-pointer select-none border-brand-800
            p-1 text-center text-lg text-white transition-bg  hover:text-white"
            style="width: 180px;"
            @click="emptyBasket"
          >
            <div
              v-if="!loading"
              class="inline-block"
            >
              Remove
            </div>
            <LoadingSpinner
              v-if="loading"
              :white="true"
              class="inline-block text-white"
            />
          </div>

          <div
            v-else
            class="addToBasket w-42 brand-button transition-200 transition-ease-in-out mb-2 mt-4 inline-block cursor-pointer select-none border-brand-800 p-1 px-3
            text-center text-lg text-white transition-bg hover:text-white"
            style="width: 180px;"
            @click="setBasket"
          >
            <div
              v-if="!loading"
              class="inline-block"
            >
              Add to basket
            </div>
            <LoadingSpinner
              v-if="loading"
              :white="true"
              class="inline-block text-white"
            />
          </div>
        </div>

        <a
          v-else
          :href="'mailto:group.golf@thegrove.co.uk?subject=' + clubPackage.Name + ' Package Waiting List&body=I would like to register my interest for the ' + clubPackage.Name + ' package, notify me via this email address when there is availability.'"
        >
          <div
            class="addToBasket brand-button transition-200 transition-ease-in-out mb-2 mt-4 inline-block cursor-pointer select-none border-brand-800 p-1 px-3
            text-center text-lg text-white transition-bg hover:text-white"
            style="width: 250px;"
          >
            <div
              class="inline-block"
            >
              Join our waiting List
            </div>
          </div>
        </a>
      </div>

      <div
        v-if="(!clubPackage.IsFull || completedFirstPayment || showFullPackages) && !club.disableLanding"
        class="text-lg xxs:inline-block"
      >
        <router-link :to="/Club/ + club.clubName + '/' + homeClubId + '#package' + index">
          <div class="cursor-pointer transition-all duration-300 hover:text-secondary-500">
            See all benefits
          </div>
        </router-link>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { SetBasketModel } from 'pmg-services';
import { nodeEnv } from '@/environment/environment';
import LoadingSpinner from '../base/loadingSpinner.vue';

export default {
  name: 'Package',

  components: {
    LoadingSpinner,
  },

  props: {
    index: {
      type: Number,
      default: undefined,
    },
    clubPackage: {
      type: Object,
      default: null,
    },
    promocode: {
      type: String,
      default: '',
    },
    homeClubId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      environment: undefined,
      selectedBundle: undefined,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      homeClubName: state => state.user.homeClubName,
      completedFirstPayment: state => state.user.completedFirstPayment,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
      disableLanding: 'disableLanding',
    }),

    isInBasket() {
      if (this.basket !== undefined && this.basket !== null && this.selectedBundle !== undefined && this.basket.BundleId === this.selectedBundle.Id) {
        return true;
      }
      return false;
    },

    showFullPackages() {
      return localStorage.getItem('showFullPackages') === 'true';
    },
  },

  mounted() {
    this.environment = nodeEnv;

    this.selectedBundle = this.clubPackage.Bundles[0];
  },

  methods: {
    setBasket() {
      this.loading = true;

      const bundle = new SetBasketModel(this.selectedBundle.Id, this.promocode, this.homeClubId, null);
      this.$store.dispatch('setBasketBundle', bundle).then(() => {
        this.$emit('basketSet');
        this.$store.dispatch('setBasketMenuState', true);
        this.loading = false;
      });
    },

    emptyBasket() {
      this.loading = true;
      this.$store.dispatch('emptyBasket').then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
@media (max-width: 500px){
  .packageBackground {
    background: none !important;
  }
}
</style>
