import memberHome from './memberHome/memberHome.vue';

const homeRoutes = [
  {
    path: '/',
    component: memberHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:view',
    component: memberHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '',
    component: memberHome,
    meta: {
      requiresAuth: true,
    },
  },
];

export default homeRoutes;
