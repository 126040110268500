<template>
  <div class="brand-body fixed inset-x-0 bottom-0 z-30 xxxs:px-4 xxxs:pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      v-if="loading"
      class="fixed left-0 top-0 z-20 flex h-screen min-h-screen w-screen min-w-full items-center justify-center"
    >
      <loadingSpinner class="text-centers mx-auto" />
    </div>

    <!-- Find Buddy -->
    <div
      v-if="!loading && buddy === undefined"
      class="z-10 transform overflow-hidden bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="mb-4">
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="users"
            class="h-6 w-6 text-green-600"
            role="img"
            viewBox="0 0 640 512"
          ><g class="fa-group"><path
            class="fa-secondary"
            fill="currentColor"
            d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
            opacity="0.4"
          /><path
            class="fa-primary"
            fill="currentColor"
            d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
          /></g></svg>
        </div>

        <div class="mt-4 text-center">
          <h3 class="brand-heading text-xl font-medium leading-6 text-gray-900">
            Find a {{ currentTheme.buddiesText }}
          </h3>
          <div class="mt-4">
            <p class="text-sm leading-5 text-gray-600">
              To add a {{ currentTheme.buddiesText }} you'll need their email address or share code (If you don't know it, ask your {{ currentTheme.buddiesText }} to send it to you).
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="notFound"
        class="mb-2 text-sm leading-5 text-red-700"
      >
        Sorry, we couldn't find anyone using that share code or email address.
      </div>

      <div>
        <input
          v-model="buddyToFind"
          type="text"
          class="w-full border bg-gray-100 p-2 px-3"
          :placeholder="'Share code or email address'"
        >
      </div>

      <div class="mt-4">
        <div class="inline-block w-1/2 pr-2">
          <SecondaryButton
            :text="'Close'"
            @click="$parent.openFindBuddy = false"
          />
        </div>

        <div class="inline-block w-1/2 pl-2">
          <PrimaryButton
            :disabled="buddyToFind === ''"
            :text="'Find'"
            @click="findBuddy"
          />
        </div>
      </div>
    </div>

    <!-- Buddy Found -->
    <div
      v-if="!loading && buddy !== undefined"
      class="z-10 transform overflow-hidden bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:max-w-2xl sm:p-6"
    >
      <div class="brand-body">
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="users"
            class="h-6 w-6 text-green-600"
            role="img"
            viewBox="0 0 640 512"
          ><g class="fa-group"><path
            class="fa-secondary"
            fill="currentColor"
            d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
            opacity="0.4"
          /><path
            class="fa-primary"
            fill="currentColor"
            d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
          /></g></svg>
        </div>

        <div class="mt-2 text-center">
          <h3 class="brand-heading mt-4 text-xl font-medium capitalize leading-6 text-gray-900">
            {{ currentTheme.buddiesText }} found
          </h3>
          <div class="mt-4 text-left">
            <div class="text-base leading-5 text-gray-600">
              <div class="mt-2 text-sm leading-normal xs:text-base">
                We've found a match. Are you sure you want to send {{ buddy.Name }} a {{ currentTheme.buddiesText }} Request? {{ buddy.Name.split(" ")[0] }} will need to confirm this request. Once confirmed, {{ buddy.Name.split(" ")[0] }} will be able to:
              </div>

              <ul class="mt-1 text-sm text-gray-700 xs:text-base">
                <li>
                  <div class="mt-1 inline-block text-xl">
                    &bull;
                  </div> Add you as a player to a booking, using your points, without confirmation
                </li>
                <li>
                  <div class="mt-1 inline-block text-xl">
                    &bull;
                  </div> Know if you have insufficient points to make a booking, and have the option to use their points instead and you will be able to do the same
                </li>
                <li>
                  <div class="mt-1 inline-block text-xl">
                    &bull;
                  </div> Additionally, any player added to a booking will have the option to cancel the booking (subject to normal cancellation terms) which will cancel and refund the entire booking.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="inline-block w-1/2 pr-2">
          <SecondaryButton
            :text="'Back'"
            @click="buddy = undefined"
          />
        </div>

        <div class="inline-block w-1/2 pl-2">
          <PrimaryButton
            :text="'Confirm'"
            @click="sendBuddyRequest"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/app/shared/services';
import LoadingSpinner from '../base/loadingSpinner.vue';
import PrimaryButton from '../base/button/primaryButton.vue';
import SecondaryButton from '../base/button/secondaryButton.vue';
import { mapGetters } from 'vuex';

export default {

  components: {
    LoadingSpinner,
    PrimaryButton,
    SecondaryButton,
  },

  data() {
    return {
      loading: false,
      buddyToFind: '',
      buddy: undefined,
      notFound: false,
    };
  },

  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  methods: {
    findBuddy() {
      this.loading = true;
      services.buddy.findPlayingPartner(this.buddyToFind).then((result) => {
        if (result.BuddyFound) {
          this.buddy = result.Buddy;
        } else {
          this.notFound = true;
        }
        this.loading = false;
      });
    },

    getBuddyInitials() {
      const split = this.buddy.Name.split(' ');
      return split[0].charAt(0) + split[(split.length - 1)].charAt(0);
    },

    sendBuddyRequest() {
      this.loading = true;
      services.buddy.createBuddyRequest(this.buddy.Target).then((result) => {
        if (result.status === 204) {
          // Success
          this.buddy = undefined;
          this.$parent.getPendingBuddies();
          this.$parent.openFindBuddy = false;
        } else {
          // Failed
        }

        this.loading = false;
      });
    },
  },

};


</script>
