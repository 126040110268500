const diningRoutes = [
  {
    path: '/dining/:clubId/:courseId',
    name: 'diningSearch',
    component: () => import('./dining.vue'),
    meta: {
      title: 'Dining',
    },
  },
];

export default diningRoutes;
