import { Services } from 'pmg-services';

function getApiUrl() {
  if (window.location.origin.includes('jcbgolfandcountryclub')) {
    return window.location.origin.replace('members', 'api');
  }

  return process.env.VUE_APP_API_URL;
}

function getUrl() {
  if (window.location.origin.includes('jcbgolfandcountryclub')) {
    return window.location.origin;
  }

  return process.env.VUE_APP_API_URL;
}

export default new Services({
  apiUrl: process.env.NODE_ENV === 'production' ? `${getApiUrl()}/api/` : `${process.env.VUE_APP_API_URL}/api/`,
  withCredentials: true,
  nodeEnv: process.env.NODE_ENV,
  unauthorizedRoute: process.env.NODE_ENV === 'production' ? `${getUrl()}Login` : `${process.env.VUE_APP_BASE_URL}Login`,
});
