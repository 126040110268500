const ecommerceRoutes = [
  {
    path: '/book',
    name: 'ecomSearch',
    component: () => import('./ecommerce.vue'),
    props: true,
    meta: {
      title: 'The Grove',
    },
  },
  {
    path: '/book/dining',
    name: 'ecomSearchDining',
    component: () => import('./ecommerce.vue'),
    props: true,
    meta: {
      title: 'The Grove',
    },
  },
  {
    path: '/book/additional',
    name: 'ecomSearchAdditional',
    component: () => import('./ecommerce.vue'),
    props: true,
    meta: {
      title: 'The Grove',
    },
  },
];

export default ecommerceRoutes;
